var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { redirectTo } from '@/js/utilities/auth';
import { unsetCookieEntry } from '@/js/utilities/cookie';
import { PageElement } from '@/js/pages/base/page-element';
import config from '@/js/config';
const style = await import('@/js/style');
let GlyCombLogout = class GlyCombLogout extends PageElement {
    firstUpdated() {
        // Remove the token from cookie.
        unsetCookieEntry(config.cookie.jwt_api_key);
        // Redirect to top page
        redirectTo('./');
    }
    render() {
        return html `Please wait...`;
    }
};
GlyCombLogout.styles = style.cssResult;
GlyCombLogout = __decorate([
    customElement('glycomb-logout')
], GlyCombLogout);
export { GlyCombLogout };
