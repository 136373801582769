var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
const style = await import('@/js/style');
let GlyCombRegistrationUpload = class GlyCombRegistrationUpload extends LitElement {
    constructor() {
        super(...arguments);
        this.selectedIdx = 0;
    }
    // @query('#glycolipids')
    // glycoLipidsElm: HTMLLIElement;
    // @query('#glycosides')
    // glycoSidesElm: HTMLLIElement;
    firstUpdated() {
        this.glycoPeptidesElm.addEventListener('click', (e) => {
            e.preventDefault();
            this.selectedIdx = 0;
        });
        this.glycoProteinsElm.addEventListener('click', (e) => {
            e.preventDefault();
            this.selectedIdx = 1;
        });
        // this.glycoLipidsElm.addEventListener('click', (e: MouseEvent) => {
        //   e.preventDefault();
        //   this.selectedIdx = 2;
        // });
        // this.glycoSidesElm.addEventListener('click', (e: MouseEvent) => {
        //   e.preventDefault();
        //   this.selectedIdx = 3;
        // });
    }
    render() {
        return html `
      <div class="tabs is-boxed is-left">
        <ul>
          <li
            id="glycopeptides"
            class="${this.selectedIdx === 0 ? 'is-active' : ''}"
          >
            <a href="#">Glycopeptides</a>
          </li>
          <li
            id="glycoproteins"
            class="${this.selectedIdx === 1 ? 'is-active' : ''}"
          >
            <a href="#">Glycoproteins</a>
          </li>
          <!--
          <li
            id="glycolipids"
            class="\${this.selectedIdx === 2 ? 'is-active' : ''}"
          >
            <a href="#">Glycolipids</a>
          </li>
          <li
            id="glycosides"
            class="\${this.selectedIdx === 3 ? 'is-active' : ''}"
          >
            <a href="#">Glycosides</a>
          </li>
           -->
        </ul>
      </div>

      <glycomb-registration-upload-glycopeptides
        .hidden=${this.selectedIdx !== 0}
      ></glycomb-registration-upload-glycopeptides>

      <glycomb-registration-upload-glycoproteins
        .hidden=${this.selectedIdx !== 1}
      ></glycomb-registration-upload-glycoproteins>

      ${this.selectedIdx >= 2
            ? html `
            <div class="columns">
              <div class="column">Coming soon...</div>
            </div>
          `
            : html ``}
    `;
    }
};
GlyCombRegistrationUpload.styles = style.cssResult;
__decorate([
    property({ type: Number })
], GlyCombRegistrationUpload.prototype, "selectedIdx", void 0);
__decorate([
    query('#glycopeptides')
], GlyCombRegistrationUpload.prototype, "glycoPeptidesElm", void 0);
__decorate([
    query('#glycoproteins')
], GlyCombRegistrationUpload.prototype, "glycoProteinsElm", void 0);
GlyCombRegistrationUpload = __decorate([
    customElement('glycomb-registration-upload')
], GlyCombRegistrationUpload);
export { GlyCombRegistrationUpload };
