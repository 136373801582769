var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { updateGtcIdAndImageMap as _updateGtcIdAndImageMap, } from '@glycomb/glycomb-utils/gtc_glycan_image';
import { AuthorizationRequiredPageElement } from '@/js/pages/base/authorization-required-page-element';
import { GlyCombSubmissionMixin } from '@/js/pages/mixins/glycomb-submission-mixin';
import config from '@/js/config';
import { showUserInput, showAccessionNumber, showAminoAcidSequence, showDate, showGlycanStructure, showLog, showAdditionalData, showPublishStatus, showSite, } from '@/js/utilities/view_helper';
const GlyCombSubmissionBase = GlyCombSubmissionMixin(AuthorizationRequiredPageElement);
const style = await import('@/js/style');
const updateGtcIdAndImageMap = _updateGtcIdAndImageMap(config.gtc_glycan_image.snfg_glycan_image_url, config.gtc_glycan_image.wurcs2image_glycan_image_url, config.gtc_glycan_image.glycan_image_load_interval);
let GlyCombGlycopeptideSubmission = class GlyCombGlycopeptideSubmission extends GlyCombSubmissionBase {
    updateGtcIdAndImageMap(gtcIds) {
        updateGtcIdAndImageMap(gtcIds, this);
    }
    showLine(line) {
        if (line.length >= 3) {
            const sequence = line[0];
            const site = line[1];
            const glycan = line[2];
            const additionalData = line.length >= 4 ? line[3] : '';
            return html `
        <tr>
          <td>${showAminoAcidSequence(sequence, site)}</td>
          <td>${showSite(site)}</td>
          <td>${showGlycanStructure(glycan, this.gtcIdAndImageMap)}</td>
          <td>${showAdditionalData(additionalData)}</td>
        </tr>
      `;
        }
        return html `
      <tr>
        <td colspan="4">Invalid entry "${showUserInput(line.join('\t'))}"</td>
      </tr>
    `;
    }
    renderIfLoggedIn() {
        return html `
      <section class="section">
        <div class="container">
          <div class="block">
            <h1 class="title">Glycopeptide Submission Entry</h1>

            ${Option.isSome(this.submissionOpt)
            ? html `
              <div class="table-container">
                <table class="table is-striped is-fullwidth">
                  <thead>
                    <tr>
                      <th>Amino acid sequence</th>
                      <th>Glycosylation site</th>
                      <th>Glycan structure</th>
                      <th>Additional data</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${this.submissionList.map(line => this.showLine(line))}
                  </tbody>
                </table>
              </div>
              <fieldset>
                <div class="field">
                  <label class="label is-medium">Submission Number</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${this.submissionOpt.value.receptionNumber}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-key" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Submission Date</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showDate(this.submissionOpt.value.receptionDate)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Submission Log</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showLog(this.submissionOpt.value.receptionLog)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Accession Number</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showAccessionNumber(this.submissionOpt.value.accession)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-key" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Registration Date</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showDate(this.submissionOpt.value.accessionDate)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Registration Log</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showLog(this.submissionOpt.value.accessionLog)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Status</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showPublishStatus(this.submissionOpt.value.publicationFlag, this.submissionOpt.value.accessionFlag, this.submissionOpt.value.accessionLog)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-lock" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Publication Date</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showDate(this.submissionOpt.value.publicationDate)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label is-medium">Publication Log</label>
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${showLog(this.submissionOpt.value.publicationLog)}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                </div>
              </fieldset>
              ${this.submissionOpt.value.publicationFlag
                ? ''
                : html `
                      ${this.submissionOpt.value.accession
                    ? html `
                            <form class="mt-4">
                              <div class="field">
                                <div class="buttons is-centered">
                                  <button
                                    class="button is-medium is-success"
                                    @click=${this.publishButtonClickHandler}
                                  >
                                    <span class="icon">
                                      <glycomb-fa-icon
                                        icon="fas fa-lock-open"
                                        size="is-4"
                                      ></glycomb-fa-icon>
                                    </span>
                                    <span>Open to the public</span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          `
                    : html ``}
                    `}
            `
            : html `<p>Data not found.</p>`}
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombGlycopeptideSubmission.styles = style.cssResult;
GlyCombGlycopeptideSubmission = __decorate([
    customElement('glycomb-glycopeptide-submission')
], GlyCombGlycopeptideSubmission);
export { GlyCombGlycopeptideSubmission };
