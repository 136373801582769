import { LitElement } from 'lit';
import { reflectStylesToLightDOM } from '@/js/style';
import { showCookiePolicyNotificationIfNeeded } from '@/js/utilities/cookie';
import { watchTokenExpirationDate } from '@/js/utilities/auth';
import { getTargetType } from '@/js/utilities/url';
import config from '@/js/config';
function addPhaseBannerToDOM() {
    const banner = document.createElement('glycomb-phase-banner');
    banner.setAttribute('target', getTargetType());
    banner.setAttribute('siteName', 'GlyComb');
    banner.innerHTML = `
    <span>
      <p>
        <b>GlyComb has been officially released on October 2, 2023 JST.</b>
      </p>
      <p>
        You can now browse the official version at
        <a
          class="glycomb-phase-banner-link"
          href="https://glycomb.glycosmos.org/"
        >
          https://glycomb.glycosmos.org</a
        >.
      </p>
    </span>
  `;
    const content = document.querySelector('.glycomb-wrapper > .glycomb-content');
    // Login/Logout page does not contain `.glycomb-wrapper > .glycomb-content` elements.
    // So if it does not exist, we can simply ignore such a case.
    if (content !== null) {
        content.prepend(banner);
    }
}
export class PageElement extends LitElement {
    constructor() {
        super();
        reflectStylesToLightDOM();
        addPhaseBannerToDOM();
        showCookiePolicyNotificationIfNeeded();
        watchTokenExpirationDate(config.expire_time_check_interval, config.expire_time_check_threshold);
    }
}
