import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import * as Option from 'fp-ts/Option';
import * as String from 'fp-ts/string';
import { range } from '@glycomb/glycomb-utils';
import { isGtcId } from '@/js/utilities/gtc_glycan_image';
export const showDate = (date) => (date === '' ? '-' : date);
export const showLog = (log) => (log === '' ? '-' : log);
export const showEmail = (email) => {
    const defaultValue = '-';
    if (typeof email === 'string') {
        return email === '' ? defaultValue : email;
    }
    if (Option.isNone(email)) {
        return defaultValue;
    }
    const emailValue = email.value;
    if (emailValue) {
        return emailValue;
    }
    return defaultValue;
};
export const showAccessionNumber = (accessionNumber) => accessionNumber || 'Processing';
export const showPublishStatus = (publicationFlag, accessionFlag, accessionLog) => {
    if (publicationFlag) {
        return 'Published';
    }
    if (accessionFlag) {
        return 'Unpublished';
    }
    if (accessionLog) {
        return `Error: ${accessionLog}`;
    }
    return 'Pending';
};
export const showUserInput = (line) => unsafeHTML(line
    .split('')
    .map(ch => ch === '\t'
    ? `<span class="glycomb-registration-user-input-separator">&nbsp;</span>`
    : ch)
    .join(''));
const highlightResidue = (residue) => `<span class="glycomb-registration-highlight-target-aa-site">${residue}</span>`;
export const showAminoAcidSequence = (sequence, site) => {
    const siteNum = typeof site === 'number' ? site : Number.parseInt(site, 10);
    if (Number.isNaN(siteNum)) {
        return sequence;
    }
    // siteNum can be a NaN value if Number.parseInt function fails.
    // In such case, the following comparison operation will also fail.
    if (siteNum >= 1 && siteNum <= sequence.length) {
        const residue = sequence[siteNum - 1];
        const prefix = sequence.slice(0, siteNum - 1);
        const suffix = sequence.slice(siteNum, sequence.length);
        return unsafeHTML(prefix + highlightResidue(residue) + suffix);
    }
    return sequence;
};
export const showSite = (site) => `${site}`;
export const showGlycanStructure = (glycan, gtcIdAndImageMap) => {
    if (isGtcId(glycan)) {
        return html `
      <glycomb-glycan-card
        style="display: inline-block;"
        title="${glycan}"
        href="https://glycosmos.org/glycans/show/${glycan}"
        base64Image="${gtcIdAndImageMap[glycan]}"
      >
      </glycomb-glycan-card>
    `;
    }
    return html `${glycan}`;
};
export const showAdditionalData = (additionalData) => html `${String.isEmpty(additionalData) ? 'N/A' : additionalData}`;
export const showUniProtId = (uniProtId) => `${uniProtId}`;
export const showLongAminoAcidSequence = (sequence, siteList) => {
    const chunkSize = 10;
    const chunkPerLine = 5;
    const siteNumList = siteList.map(site => typeof site === 'number' ? site : Number.parseInt(site, 10));
    const lineNum = Math.ceil(sequence.length / (chunkSize * chunkPerLine));
    const hlSequence = sequence
        .split('')
        .map((ch, i) => siteNumList.includes(i + 1) ? { ch, hl: true } : { ch, hl: false });
    const lines = range(0, lineNum).flatMap(idx => {
        const markerList = [];
        const chunkList = [];
        const startIdx = idx * chunkSize * chunkPerLine;
        const subsequence = hlSequence.slice(startIdx, startIdx + chunkSize * chunkPerLine);
        let remainingLength = subsequence.length;
        for (let i = 0; remainingLength > 0; i += 1) {
            const innerStartIdx = i * 10;
            const chunk = subsequence.slice(innerStartIdx, innerStartIdx + chunkSize);
            const hlChunk = chunk.map(c => (c.hl ? highlightResidue(c.ch) : c.ch));
            // Push chunk
            chunkList.push(hlChunk.join(''));
            // Push marker
            const markerNum = startIdx + innerStartIdx + chunkSize;
            const marker = markerNum.toString();
            const markerWithPad = marker.padStart(chunkSize, ' ');
            markerList.push(markerWithPad);
            remainingLength -= chunkSize;
        }
        return [markerList.join(' '), chunkList.join(' '), ''];
    });
    return unsafeHTML(lines.join('\n').trimEnd());
};
const getExcelColumnName = (idx) => {
    // <https://qiita.com/kawanet/items/25540cf64f37e4c85172>
    const idx2col = (n) => (n > 25 ? idx2col(Math.floor(n / 26 - 1)) : '') +
        ((n % 26) + 10).toString(36).toUpperCase();
    return idx2col(idx);
};
export const getColumnDisplayName = (idx, inputFileContent) => {
    if (inputFileContent.type === 'excel') {
        return getExcelColumnName(idx);
    }
    // inputFileContent.type === 'text'
    return (idx + 1).toString();
};
