var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { PageElement } from '@/js/pages/base/page-element';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { hideLoadingOverlay, showLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getBaseUrl, getSearchTextApiPath } from '../utilities/url';
import { handleGlyCombError } from '../utilities/error_handling';
import { GlyCombBrowserLocalError } from '../types/error_handling';
const style = await import('@/js/style');
let GlyCombIndex = class GlyCombIndex extends PageElement {
    constructor() {
        super(...arguments);
        this.isSubmitReady = false;
    }
    checkSubmitReady() {
        if (this.searchTextArea.value === '') {
            this.isSubmitReady = false;
        }
        else {
            this.isSubmitReady = true;
        }
    }
    textAreaInputHandler(_) {
        this.checkSubmitReady();
    }
    textAreaKeydownHandler(e) {
        // Enable inserting TAB characters
        if (e.key === 'Tab') {
            e.preventDefault();
            const text = this.searchTextArea.value;
            const startPos = this.searchTextArea.selectionStart;
            const endPos = this.searchTextArea.selectionEnd;
            this.searchTextArea.value = `${text.substring(0, startPos)}\t${text.substring(endPos)}`;
            this.searchTextArea.selectionStart = startPos + 1;
            this.searchTextArea.selectionEnd = startPos + 1;
        }
        this.checkSubmitReady();
    }
    async searchButtonClickHandler(_) {
        showLoadingOverlay();
        const queryString = this.searchTextArea.value;
        const params = new URLSearchParams();
        params.append('raw_data', queryString);
        const responseOpt = await fetchFromGlyCombApi({
            url: `${getBaseUrl()}${getSearchTextApiPath()}`,
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            body: params,
            authorizationRequired: false,
        });
        await handleGlyCombError(async () => {
            if (Option.isSome(responseOpt)) {
                const { accessionFlag, publicationFlag } = responseOpt.value.contents;
                if (accessionFlag && publicationFlag) {
                    const { accession, datatype } = responseOpt.value.contents;
                    window.location.href = `/${datatype.toLowerCase()}-entry?accession_number=${accession}`;
                }
                else {
                    const errcode = 'gcb-frontendE003';
                    throw new GlyCombBrowserLocalError('', errcode);
                }
            }
        });
        hideLoadingOverlay();
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <h1 class="title">Entry Search</h1>

          <div class="column">
            <div class="field">
              <label class="label">
                Paste your data in TSV format to the text area below:
              </label>
              <textarea
                id="search_text_area"
                class="textarea"
                rows="15"
                placeholder="Paste your data here..."
                @input="${this.textAreaInputHandler}"
                @keydown="${this.textAreaKeydownHandler}"
              ></textarea>
            </div>

            <div class="field">
              <div class="buttons is-centered">
                <button
                  class="button is-medium is-success"
                  .disabled=${!this.isSubmitReady}
                  @click="${this.searchButtonClickHandler}"
                >
                  <span class="icon">
                    <glycomb-fa-icon
                      id="submitButtonIcon"
                      icon="fas fa-search"
                      size="is-4"
                    ></glycomb-fa-icon>
                  </span>
                  <span>Search</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombIndex.styles = style.cssResult;
__decorate([
    query('#search_text_area')
], GlyCombIndex.prototype, "searchTextArea", void 0);
__decorate([
    property({ type: Boolean })
], GlyCombIndex.prototype, "isSubmitReady", void 0);
GlyCombIndex = __decorate([
    customElement('glycomb-search')
], GlyCombIndex);
export { GlyCombIndex };
