import izitoast from 'izitoast';
import * as Option from 'fp-ts/Option';
import { readCookieEntry as _readCookieEntry, setCookieEntry as _setCookieEntry, unsetCookieEntry as _unsetCookieEntry, } from '@glycomb/glycomb-utils/cookie';
import config from '@/js/config';
export function isCookiePolicyAccepted() {
    const cookieArray = document.cookie.split(';').map(c => c.trim());
    return cookieArray.includes(`${config.cookie.cookie_policy_key}=${config.cookie.cookie_policy_value}`);
}
export const readCookieEntry = _readCookieEntry(isCookiePolicyAccepted);
export const setCookieEntry = _setCookieEntry(isCookiePolicyAccepted);
export const unsetCookieEntry = _unsetCookieEntry(isCookiePolicyAccepted);
export function getApiKey() {
    return readCookieEntry(config.cookie.jwt_api_key);
}
export function showCookiePolicyNotificationIfNeeded() {
    const cookiePolicyOpt = readCookieEntry(config.cookie.cookie_policy_key);
    const cookiePolicy = Option.getOrElse(() => '')(cookiePolicyOpt);
    if (cookiePolicy !== config.cookie.cookie_policy_value) {
        izitoast.show({
            theme: 'dark',
            icon: 'fas fa-cookie-bite',
            title: 'Your privacy',
            message: 'In order to deliver personalized services and function effectively, this website will store cookies on your device. Please close this message and continue to use this site if you agree.',
            position: 'bottomCenter',
            close: false,
            progressBar: false,
            drag: false,
            timeout: false,
            buttons: [
                [
                    '<button>Accept & Close</button>',
                    (_, toast) => {
                        // Set cookiePolicy cookie
                        document.cookie = [
                            `${config.cookie.cookie_policy_key}=${config.cookie.cookie_policy_value}`,
                            'path=/',
                        ].join(';');
                        izitoast.hide({}, toast);
                    },
                    false, // No focus by default
                ],
            ],
        });
    }
}
