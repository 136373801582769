var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getUrlQueryParameter, getBaseUrl, getEntryDataApiPath, getSequenceDataApiPath, } from '@/js/utilities/url';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { isGtcId } from '@/js/utilities/gtc_glycan_image';
export const GlyCombEntryMixin = (superClass) => {
    class GlyCombEntryMixinClass extends superClass {
        constructor() {
            super(...arguments);
            // Required by IHasGtcGlycanImageElement interface
            this.gtcIdAndImageMap = {};
            this.entryOpt = Option.none;
            this.entryList = [];
            this.sequenceOpt = Option.none;
            this.accessionNumberOpt = getUrlQueryParameter('accession_number');
        }
        async updateEntry() {
            if (Option.isNone(this.accessionNumberOpt)) {
                return;
            }
            const accessionNumber = this.accessionNumberOpt.value;
            const entryResOpt = await fetchFromGlyCombApi({
                url: `${getBaseUrl()}${getEntryDataApiPath()}/${accessionNumber}`,
                method: 'GET',
                contentType: 'application/json',
                authorizationRequired: false,
            });
            this.entryOpt = Option.map(response => response.contents)(entryResOpt);
            if (Option.isSome(this.entryOpt)) {
                // Extract data
                const { datatext } = this.entryOpt.value;
                const lineList = datatext.split(/\n/).map(line => line.split(/\t/));
                const uuid = lineList[0][0];
                this.entryList = lineList.map((line, i) => i === 0 ? line : [uuid, ...line]);
                // Fetch sequence
                const seqResOpt = await fetchFromGlyCombApi({
                    url: `${getBaseUrl()}${getSequenceDataApiPath()}/${uuid}`,
                    method: 'GET',
                    contentType: 'application/json',
                    authorizationRequired: false,
                });
                this.sequenceOpt = Option.map(response => response.contents)(seqResOpt);
                // Collect GlyTouCan IDs and show the images
                const gtcIds = this.entryList
                    .map(line => line[2])
                    .filter(glycan => isGtcId(glycan));
                this.updateGtcIdAndImageMap(gtcIds);
            }
        }
        async firstUpdated() {
            showLoadingOverlay();
            await this.updateEntry();
            hideLoadingOverlay();
        }
    }
    __decorate([
        property({ type: Object })
    ], GlyCombEntryMixinClass.prototype, "gtcIdAndImageMap", void 0);
    __decorate([
        property({ type: Object })
    ], GlyCombEntryMixinClass.prototype, "entryOpt", void 0);
    __decorate([
        property({ type: Array })
    ], GlyCombEntryMixinClass.prototype, "entryList", void 0);
    __decorate([
        property({ type: Object })
    ], GlyCombEntryMixinClass.prototype, "sequenceOpt", void 0);
    return GlyCombEntryMixinClass;
};
