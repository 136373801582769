import * as Option from 'fp-ts/Option';
import config from '@/js/config';
export const getUrlQueryParameter = (paramName) => Option.fromNullable(new URL(window.location.href).searchParams.get(paramName));
export function getTargetType() {
    const { hostname } = window.location;
    if (hostname === `glycomb.${config.url.server.prod}`) {
        return 'prod';
    }
    if (hostname === `glycomb.${config.url.server.beta}`) {
        return 'beta';
    }
    if (hostname === `glycomb.${config.url.server.alpha}`) {
        return 'alpha';
    }
    return 'alpha';
}
export const getGoogleSigninPath = () => {
    const targetType = getTargetType();
    switch (targetType) {
        case 'prod':
            return config.url.google_signin_url.prod;
        case 'beta':
            return config.url.google_signin_url.beta;
        case 'alpha':
            return config.url.google_signin_url.alpha;
        case 'test':
        default:
            return config.url.google_signin_url.alpha;
    }
};
export const getServer = () => {
    const targetType = getTargetType();
    switch (targetType) {
        case 'prod':
            return config.url.server.prod;
        case 'beta':
            return config.url.server.beta;
        case 'alpha':
            return config.url.server.alpha;
        case 'test':
        default:
            return config.url.server.alpha;
    }
};
export const getBaseUrl = () => `https://glycomb.${getServer()}`;
export const getAccessionCountApiPath = () => config.url.accession_count_api_url;
export const getInputValidationApiPath = () => config.url.input_validation_api_url;
export const getRegistrationRequestApiPath = () => config.url.registration_request_api_url;
export const getSearchTextApiPath = () => config.url.search_text_api_url;
export const getSubmissionsListApiPath = () => config.url.submissions_list_api_url;
export const getSubmissionDataApiPath = () => config.url.submission_data_api_url;
export const getEntryDataApiPath = () => config.url.entry_data_api_url;
export const getSequenceDataApiPath = () => config.url.sequence_data_api_url;
export const getAccessionsListApiPath = () => config.url.accessions_list_api_url;
export const publishAccessionApiPath = () => config.url.publish_accession_api_url;
export const userProfileApiPath = () => config.url.user_profile_api_url;
