var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, query } from 'lit/decorators.js';
import izitoast from 'izitoast';
import copyToClipboard from 'clipboard-copy';
import * as Option from 'fp-ts/Option';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { handleGlyCombError } from '@/js/utilities/error_handling';
import { getBaseUrl, getInputValidationApiPath } from '@/js/utilities/url';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import * as FileUploadHelper from '@/js/utilities/file_upload_helper';
import { GlyCombApiCallError } from '@/js/types/error_handling';
import config from '@/js/config';
import { truncateLeading } from '@/js/utilities/miscellaneous';
export const GlyCombRegistrationUploadMixin = (superClass) => {
    class GlyCombRegistrationUploadMixinClass extends superClass {
        constructor() {
            super(...arguments);
            // To be overridden
            this.placeholderString = 'template placeholder';
            this.datatype = 'Glycopeptide';
            this.emptyFileName = '(No file selected)';
            this.maxFileNameLength = 100;
            this.selectedInputMethod = 'clipboard';
            this.isSubmitReady = false;
            this.inputFileName = this.emptyFileName;
            this.inputFileContent = {
                type: 'text',
                content: '',
            };
        }
        firstUpdated() {
            this.inputMethodMenuClipboard.addEventListener('click', () => {
                this.selectedInputMethod = 'clipboard';
                this.checkSubmitReady();
            });
            this.inputMethodMenuFile.addEventListener('click', () => {
                this.selectedInputMethod = 'file';
                this.checkSubmitReady();
            });
        }
        checkSubmitReady() {
            if (this.selectedInputMethod === 'clipboard' &&
                this.textareaElement.value.length !== 0) {
                this.isSubmitReady = true;
            }
            else if (this.selectedInputMethod === 'file' &&
                this.fileInputElement.files.length !== 0 &&
                this.fileUploadConfig.checkSubmitReady()) {
                this.isSubmitReady = true;
            }
            else {
                this.isSubmitReady = false;
            }
        }
        copyToClipboardButtonClickHandler(_) {
            copyToClipboard(this.placeholderString);
            // Show "Copied to your clipboard!" message to the user.
            izitoast.info({
                title: 'Info',
                message: 'Copied to your clipboard!',
                position: 'topRight',
                timeout: config.toast_notification_timeout,
            });
        }
        textAreaKeydownHandler(e) {
            // Enable inserting TAB characters
            if (e.key === 'Tab') {
                e.preventDefault();
                const text = this.textareaElement.value;
                const startPos = this.textareaElement.selectionStart;
                const endPos = this.textareaElement.selectionEnd;
                this.textareaElement.value = `${text.substring(0, startPos)}\t${text.substring(endPos)}`;
                this.textareaElement.selectionStart = startPos + 1;
                this.textareaElement.selectionEnd = startPos + 1;
            }
            this.checkSubmitReady();
        }
        textAreaInputHandler(_) {
            this.checkSubmitReady();
        }
        async fileChangeHandler(_) {
            const fullPath = this.fileInputElement.value;
            if (fullPath === '') {
                // File is deselected
                this.inputFileName = this.emptyFileName;
                // Unload the file content
                this.inputFileContent = {
                    type: 'text',
                    content: '',
                };
            }
            else {
                // File is selected
                const file = this.fileInputElement.files[0];
                this.inputFileName =
                    file.name.length < this.maxFileNameLength
                        ? file.name
                        : `...${truncateLeading(file.name, this.maxFileNameLength)}`;
                // Load the file content
                if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                    const buffer = await file.arrayBuffer();
                    this.inputFileContent = {
                        type: 'excel',
                        content: buffer,
                    };
                }
                else {
                    const text = await file.text();
                    this.inputFileContent = {
                        type: 'text',
                        content: text,
                    };
                }
            }
            this.checkSubmitReady();
        }
        async submitButtonClickHandler(e) {
            e.preventDefault();
            showLoadingOverlay();
            await handleGlyCombError(async () => {
                let content = '';
                if (this.selectedInputMethod === 'clipboard') {
                    content = this.textareaElement.value;
                }
                else {
                    content = await this.fileUploadConfig.generateContent();
                }
                content = FileUploadHelper.insertBlankLinesToTsvString(content);
                const formData = new FormData();
                formData.set('uploaded_file', content);
                // To set Content-Type field to multipart/form-data,
                // we should make the browser guess this field to work properly.
                // See <https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/>.
                const inputValidationResponseOpt = await fetchFromGlyCombApi({
                    url: `${getBaseUrl()}${getInputValidationApiPath()}`,
                    method: 'POST',
                    body: formData,
                    authorizationRequired: false,
                });
                if (Option.isSome(inputValidationResponseOpt)) {
                    const inputValidationResponse = inputValidationResponseOpt.value;
                    if (inputValidationResponse.errcode !== '') {
                        throw new GlyCombApiCallError(`Failed to fetch from ${getBaseUrl()}${getInputValidationApiPath()}`, Option.of(200), inputValidationResponse.errcode);
                    }
                    const event = new CustomEvent(config.pages.registration.state_change_event_name, {
                        bubbles: true,
                        cancelable: false,
                        composed: true,
                        detail: {
                            nextStep: 1,
                            datatype: this.datatype,
                            inputValidationContent: inputValidationResponse.contents,
                        },
                    });
                    this.dispatchEvent(event);
                }
            });
            hideLoadingOverlay();
        }
    }
    __decorate([
        query('#input-method-menu-clipboard')
    ], GlyCombRegistrationUploadMixinClass.prototype, "inputMethodMenuClipboard", void 0);
    __decorate([
        query('#input-method-menu-file')
    ], GlyCombRegistrationUploadMixinClass.prototype, "inputMethodMenuFile", void 0);
    __decorate([
        query('textarea.textarea')
    ], GlyCombRegistrationUploadMixinClass.prototype, "textareaElement", void 0);
    __decorate([
        query('input.file-input')
    ], GlyCombRegistrationUploadMixinClass.prototype, "fileInputElement", void 0);
    __decorate([
        query('#glycomb-file-upload-config')
    ], GlyCombRegistrationUploadMixinClass.prototype, "fileUploadConfig", void 0);
    __decorate([
        property({ type: String })
    ], GlyCombRegistrationUploadMixinClass.prototype, "selectedInputMethod", void 0);
    __decorate([
        property({ type: Boolean })
    ], GlyCombRegistrationUploadMixinClass.prototype, "isSubmitReady", void 0);
    __decorate([
        property({ type: String })
    ], GlyCombRegistrationUploadMixinClass.prototype, "inputFileName", void 0);
    __decorate([
        property({ type: Object })
    ], GlyCombRegistrationUploadMixinClass.prototype, "inputFileContent", void 0);
    return GlyCombRegistrationUploadMixinClass;
};
