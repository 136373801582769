var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import izitoast from 'izitoast';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getBaseUrl, getEntryDataApiPath } from '@/js/utilities/url';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import config from '@/js/config';
const style = await import('@/js/style');
let GlyCombSearchBar = class GlyCombSearchBar extends LitElement {
    constructor() {
        super(...arguments);
        this.accessionNumber = '';
    }
    accessionNumberInputKeyUpHandler(e) {
        this.accessionNumber = this.accessionNumberInput.value;
        if (e.key === 'Enter') {
            this.submit();
        }
    }
    searchButtonClickHandler(_) {
        this.accessionNumber = this.accessionNumberInput.value;
        this.submit();
    }
    showErrorMessage() {
        izitoast.error({
            title: 'Error',
            message: `Accession number "${this.accessionNumber}" was not found.`,
            position: 'topRight',
            timeout: config.toast_notification_timeout,
        });
    }
    async submit() {
        showLoadingOverlay();
        const responseOpt = await fetchFromGlyCombApi({
            url: `${getBaseUrl()}${getEntryDataApiPath()}/${this.accessionNumber}`,
            method: 'GET',
            contentType: 'application/json',
            authorizationRequired: false,
        });
        const entryOpt = Option.map(response => response.contents)(responseOpt);
        hideLoadingOverlay();
        if (Option.isSome(entryOpt)) {
            const datatype = entryOpt.value.datatype;
            switch (datatype) {
                case 'Glycopeptide':
                    window.location.href = `/glycopeptide-entry?accession_number=${this.accessionNumber}`;
                    break;
                case 'Glycoprotein':
                    window.location.href = `/glycoprotein-entry?accession_number=${this.accessionNumber}`;
                    break;
                case 'Glycolipid':
                    window.location.href = `/glycolipid-entry?accession_number=${this.accessionNumber}`;
                    break;
                case 'Glycoside':
                    window.location.href = `/glycoside-entry?accession_number=${this.accessionNumber}`;
                    break;
                default:
                    this.showErrorMessage();
            }
        }
        else {
            this.showErrorMessage();
        }
    }
    render() {
        return html `
      <div class="field has-addons">
        <p class="control">
          <input
            id="accession_number_input"
            class="input"
            type="text"
            placeholder="Accession Number..."
            @keyup="${this.accessionNumberInputKeyUpHandler}"
          />
        </p>
        <p class="control">
          <button
            class="button"
            ?disabled=${this.accessionNumber === ''}
            @click="${this.searchButtonClickHandler}"
          >
            <span class="icon">
              <glycomb-fa-icon icon="fas fa-search"></glycomb-fa-icon>
            </span>
          </button>
        </p>
      </div>
    `;
    }
};
GlyCombSearchBar.styles = style.cssResult;
__decorate([
    query('#accession_number_input')
], GlyCombSearchBar.prototype, "accessionNumberInput", void 0);
__decorate([
    property({ type: String })
], GlyCombSearchBar.prototype, "accessionNumber", void 0);
GlyCombSearchBar = __decorate([
    customElement('glycomb-search-bar')
], GlyCombSearchBar);
export { GlyCombSearchBar };
