var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import * as Array from 'fp-ts/Array';
import messages from '@/js/utilities/error_messages';
export const GlyCombInputTableMixin = (superClass) => {
    class GlyCombInputTableMixinClass extends superClass {
        constructor() {
            super(...arguments);
            this.paragraphNumber = 0;
            this.gtcIdAndImageMap = {};
        }
        static showWarningOrErrorMessage(code) {
            const target = messages.find(m => m.code === code);
            return target
                ? html `<b>${code}</b>: ${target.message}`
                : html `Unknown errcode/warcode "${code}"`;
        }
        static containsNoError(line) {
            return Array.isEmpty(line.errors);
        }
        static containsNoWarning(line) {
            return Array.isEmpty(line.warnings);
        }
        static containsNoErrorOrWarning(line) {
            return (GlyCombInputTableMixinClass.containsNoError(line) &&
                GlyCombInputTableMixinClass.containsNoWarning(line));
        }
        static containsW001(line) {
            // gcb-input-validationW001 means duplicated inputs.
            return line.warnings
                .map(w => w.code)
                .includes('gcb-input-validationW001');
        }
        static shouldShowLineInfo(line) {
            return (GlyCombInputTableMixinClass.containsNoErrorOrWarning(line) ||
                (GlyCombInputTableMixinClass.containsNoError(line) &&
                    !GlyCombInputTableMixinClass.containsW001(line)));
        }
        static getStatusBackgroundColor(line) {
            if (GlyCombInputTableMixinClass.containsNoErrorOrWarning(line)) {
                return 'has-background-success-light';
            }
            if (GlyCombInputTableMixinClass.containsNoError(line)) {
                return 'has-background-warning-light';
            }
            return 'has-background-danger-light';
        }
        static showIcon(line) {
            if (GlyCombInputTableMixinClass.containsNoErrorOrWarning(line)) {
                return html `
          <span class="has-text-success icon">
            <glycomb-fa-icon icon="fas fa-check" size="is-6"></glycomb-fa-icon>
          </span>
        `;
            }
            const warningIcon = Array.isNonEmpty(line.warnings)
                ? html `
            <span class="glycomb-tooltip-right-top glycomb-tooltip-warning">
              <span
                class="has-text-warning icon glycomb-registration-assertion-icon"
              >
                <glycomb-fa-icon icon="fas fa-exclamation-triangle" size="is-6">
                </glycomb-fa-icon>
              </span>
              <span class="glycomb-tooltip-content">
                <div class="content glycomb-registration-tooltip-content">
                  <ul>
                    ${line.warnings.map(warning => html `
                          <li>
                            ${GlyCombInputTableMixinClass.showWarningOrErrorMessage(warning.code)}
                          </li>
                        `)}
                  </ul>
                </div>
              </span>
            </span>
          `
                : html ``;
            const errorIcon = Array.isNonEmpty(line.errors)
                ? html `
            <span class="glycomb-tooltip-right-top glycomb-tooltip-danger">
              <span
                class="has-text-danger icon glycomb-registration-assertion-icon"
              >
                <glycomb-fa-icon icon="fas fa-exclamation-circle" size="is-6">
                </glycomb-fa-icon>
              </span>
              <span class="glycomb-tooltip-content">
                <div class="content glycomb-registration-tooltip-content">
                  <ul>
                    ${line.errors.map(error => html `
                          <li>
                            ${GlyCombInputTableMixinClass.showWarningOrErrorMessage(error.code)}
                          </li>
                        `)}
                  </ul>
                </div>
              </span>
            </span>
          `
                : html ``;
            return html `${warningIcon} ${errorIcon}`;
        }
    }
    __decorate([
        property({ type: Number })
    ], GlyCombInputTableMixinClass.prototype, "paragraphNumber", void 0);
    __decorate([
        property({ type: Object, attribute: false })
    ], GlyCombInputTableMixinClass.prototype, "paragraph", void 0);
    __decorate([
        property({ type: Object, attribute: false })
    ], GlyCombInputTableMixinClass.prototype, "gtcIdAndImageMap", void 0);
    return GlyCombInputTableMixinClass;
};
