var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombIndex_1;
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import izitoast from 'izitoast';
import * as Option from 'fp-ts/Option';
import { readCookieEntry, unsetCookieEntry } from '@/js/utilities/cookie';
import { handleGlyCombError } from '@/js/utilities/error_handling';
import { GlyCombBrowserLocalError } from '@/js/types/error_handling';
import { PageElement } from '@/js/pages/base/page-element';
import glycombLogo from '@/assets/images/glycomb_logo.png';
import glycosmosLogo from '@/assets/images/glycosmos.svg';
import config from '@/js/config';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { getBaseUrl, getAccessionCountApiPath } from '@/js/utilities/url';
const style = await import('@/js/style');
let GlyCombIndex = GlyCombIndex_1 = class GlyCombIndex extends PageElement {
    constructor() {
        super(...arguments);
        this.glycopeptideAccessionCount = 0;
        this.glycoproteinAccessionCount = 0;
    }
    static async showErrorMessageIfExists() {
        const errcodeOpt = readCookieEntry(config.cookie.error_code_key);
        await handleGlyCombError(async () => {
            if (Option.isSome(errcodeOpt)) {
                const errcode = errcodeOpt.value;
                throw new GlyCombBrowserLocalError('', errcode);
            }
        });
        unsetCookieEntry(config.cookie.error_code_key);
    }
    static showLoginMessageIfExists() {
        const loggedinOpt = readCookieEntry(config.cookie.login_check_key);
        if (Option.isSome(loggedinOpt)) {
            izitoast.success({
                title: 'Success',
                message: 'You have successfully logged in to GlyComb.',
                position: 'topRight',
                timeout: config.toast_notification_timeout,
            });
            unsetCookieEntry(config.cookie.login_check_key);
        }
    }
    async fetchAccessionCount() {
        let responseOpt = await fetchFromGlyCombApi({
            url: `${getBaseUrl()}${getAccessionCountApiPath()}?datatype=Glycopeptide`,
            method: 'GET',
            contentType: 'application/json',
            authorizationRequired: false,
        });
        if (Option.isSome(responseOpt)) {
            this.glycopeptideAccessionCount = responseOpt.value.contents;
        }
        responseOpt = await fetchFromGlyCombApi({
            url: `${getBaseUrl()}${getAccessionCountApiPath()}?datatype=Glycoprotein`,
            method: 'GET',
            contentType: 'application/json',
            authorizationRequired: false,
        });
        if (Option.isSome(responseOpt)) {
            this.glycoproteinAccessionCount = responseOpt.value.contents;
        }
    }
    async firstUpdated() {
        GlyCombIndex_1.showErrorMessageIfExists();
        GlyCombIndex_1.showLoginMessageIfExists();
        await this.fetchAccessionCount();
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-12 has-text-centered">
              <img alt="GlyComb logo" title="GlyComb" src="${glycombLogo}"></img>
            </div>
            <div class="column is-12-tablet is-6-desktop is-6-widescreen">
              <div class="notification is-success has-text-centered">
                <p class="title"><a href="/glycopeptide-accession" class="glycomb-index-stats-link">${this.glycopeptideAccessionCount}</a></p>
                <p class="subtitle">Glycopeptides</p>
              </div>
            </div>
            <div class="column is-12-tablet is-6-desktop is-6-widescreen">
              <div class="notification is-link has-text-centered">
                <p class="title"><a href="/glycoprotein-accession" class="glycomb-index-stats-link">${this.glycoproteinAccessionCount}</a></p>
                <p class="subtitle">Glycoproteins</p>
              </div>
            </div>
            <!--
            <div class="column is-12-tablet is-6-desktop is-3-widescreen">
              <div class="notification has-background-info-dark has-text-grey-lighter has-text-centered">
                <p class="title">Coming soon...</p>
                <p class="subtitle">Glycolipids</p>
              </div>
            </div>
            <div class="column is-12-tablet is-6-desktop is-3-widescreen">
              <div class="notification has-background-primary-dark has-text-grey-lighter has-text-centered">
                <p class="title">Coming soon...</p>
                <p class="subtitle">Glycosides</p>
              </div>
            </div>
             -->
          </div>


          <div class="columns is-multiline">
            <div class="column is-12-tablet is-6-desktop is-8-widescreen">
              <div class="tile is-ancestor">
                <div class="tile is-parent is-vertical">
                  <nav class="tile is-child panel">
                    <p class="panel-heading">
                      About GlyComb
                    </p>
                    <div class="panel-block">
                      <div class="content">
                        GlyComb is a repository for glycoconjugate information, including glycopeptides, glycoproteins, glycolipids and glycosides.  At the current prototype stage, GlyComb takes as input glycopeptides and the glycans attached to them (specified as GlyTouCan IDs).
                      </div>
                    </div>
                  </nav>
                  <nav class="tile is-child panel">
                    <p class="panel-heading">
                      What's New
                    </p>
                    <div class="panel-block">
                      <div class="content">
                        <ul style="margin-top: 0;">
                          <li>October 2, 2023: GlyComb 1.0 has been officially released.</li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div class="column is-12-tablet is-6-desktop is-4-widescreen">
              <div class="tile is-ancester">
                <div class="tile is-parent is-vertical">
                  <div class="tile is-child has-text-centered">
                    <a href="https://glycosmos.org">
                      <img alt="GlyCosmos logo" title="GlyCosmos" src="${glycosmosLogo}"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-12-tablet">
              <twitter-timeline screenName="GlyCosmos" tweetLimit="5" style="display: block; height: 500px; overflow-y: scroll;"></twitter-timeline>
            </div>
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombIndex.styles = style.cssResult;
__decorate([
    property({ type: Number })
], GlyCombIndex.prototype, "glycopeptideAccessionCount", void 0);
__decorate([
    property({ type: Number })
], GlyCombIndex.prototype, "glycoproteinAccessionCount", void 0);
GlyCombIndex = GlyCombIndex_1 = __decorate([
    customElement('glycomb-index')
], GlyCombIndex);
export { GlyCombIndex };
