var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombLoginTimeoutOverlay_1;
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { getGoogleSigninPath } from '@/js/utilities/url';
const style = await import('@/js/style');
let GlyCombLoginTimeoutOverlay = GlyCombLoginTimeoutOverlay_1 = class GlyCombLoginTimeoutOverlay extends LitElement {
    static showOverlay() {
        if (GlyCombLoginTimeoutOverlay_1.overlayInstance === null) {
            GlyCombLoginTimeoutOverlay_1.overlayInstance = document.createElement('glycomb-login-timeout-overlay');
        }
        document.body.appendChild(GlyCombLoginTimeoutOverlay_1.overlayInstance);
    }
    static hideOverlay() {
        document.body.removeChild(GlyCombLoginTimeoutOverlay_1.overlayInstance);
    }
    render() {
        return html `
      <div class="modal is-active glycomb-block-modal">
        <button
          class="modal-background glycomb-modal-background"
          @click="${GlyCombLoginTimeoutOverlay_1.hideOverlay}"
        ></button>
        <div class="modal-card">
          <header class="modal-card-head">
            <nav class="level">
              <div class="level-left">
                <div class="level-item">
                  <glycomb-fa-icon
                    icon="fas fa-exclamation-triangle"
                    size="is-3"
                  ></glycomb-fa-icon>
                </div>
                <div class="level-item has-text-centered">
                  <p class="modal-card-title">
                    You're about to log out after 1 minute!
                  </p>
                </div>
              </div>
              <div class="level-right is-hidden-mobile">
                <div class="level-item">
                  <button
                    class="delete"
                    aria-label="close"
                    @click="${GlyCombLoginTimeoutOverlay_1.hideOverlay}"
                  ></button>
                </div>
              </div>
            </nav>
          </header>
          <section class="modal-card-body">
            <div class="block">
              Please try to log in to GlyComb with Google account again from
              below.
            </div>
          </section>
          <footer class="modal-card-foot">
            <div class="columns">
              <div class="column has-text-centered">
                <glycomb-google-signin-button
                  theme="dark"
                  href="${getGoogleSigninPath()}"
                >
                </glycomb-google-signin-button>
              </div>
              <div class="column has-text-centered">
                <a class="button is-danger is-rounded" href="./logout">
                  <span class="icon">
                    <glycomb-fa-icon
                      icon="fas fa-sign-out-alt"
                      size="is-5"
                    ></glycomb-fa-icon>
                  </span>
                  <span>Logout</span>
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    `;
    }
};
GlyCombLoginTimeoutOverlay.styles = style.cssResult;
GlyCombLoginTimeoutOverlay.overlayInstance = null;
GlyCombLoginTimeoutOverlay = GlyCombLoginTimeoutOverlay_1 = __decorate([
    customElement('glycomb-login-timeout-overlay')
], GlyCombLoginTimeoutOverlay);
export { GlyCombLoginTimeoutOverlay };
