var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { GlyCombInputTableMixin } from '@/js/widgets/input_table/glycomb-input-table-mixin';
import { showUniProtId, showGlycanStructure, showSite, showUserInput, } from '@/js/utilities/view_helper';
import { GlyCombProteinSequenceMixin } from '../protein_sequence/glycomb-protein-sequence-mixin';
const GlyCombProteinSequenceBase = GlyCombProteinSequenceMixin(LitElement);
const GlyCombInputTableBase = GlyCombInputTableMixin(GlyCombProteinSequenceBase);
const style = await import('@/js/style');
let GlyCombInputTableGlycoprotein = class GlyCombInputTableGlycoprotein extends GlyCombInputTableBase {
    showLine(line) {
        const shouldShowLineInfo = GlyCombInputTableBase.shouldShowLineInfo(line);
        return html `
      <tr>
        <td
          class="${GlyCombInputTableBase.getStatusBackgroundColor(line)}"
          rowspan=${shouldShowLineInfo ? '2' : '1'}
        >
          ${GlyCombInputTableBase.showIcon(line)}
        </td>
        <td>${showUserInput(line.line)}</td>
        ${shouldShowLineInfo
            ? html `
              <td>${showUniProtId(line.string)}</td>
              <td>${showSite(line.site)}</td>
              <td>
                ${showGlycanStructure(line.glycan, this.gtcIdAndImageMap)}
              </td>
            `
            : html `<td colspan="3"></td>`}
      </tr>

      ${shouldShowLineInfo
            ? html `
            <tr>
              <td colspan="4">
                <glycomb-collapsible-message
                  messageClass="is-light"
                  messageHeaderClass=""
                  headerTitle=${`Show sequence of "${line.string}"`}
                  @expand=${() => this.fetchSequence(line.string)}
                >
                  <div class="glycomb-float-button-wrapper">
                    ${this.showSequence(line.string, line.site)}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.uniprot.org/uniprotkb/${line.string}/entry"
                      ><button class="button glycomb-float-button is-light">
                        <span class="icon"
                          ><glycomb-fa-icon
                            icon="fas fa-external-link-alt"
                            size="is-6"
                          ></glycomb-fa-icon></span
                        ><span>UniProt</span>
                      </button>
                    </a>
                  </div>
                </glycomb-collapsible-message>
              </td>
            </tr>
          `
            : html ``}
    `;
    }
    render() {
        return html `
      <div class="block">
        <div class="divider">Sequence ${this.paragraphNumber}</div>
        <div class="glycomb-registration-table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Status</th>
                <th>User input</th>
                <th>UniProt ID</th>
                <th>Glycosylation site</th>
                <th>Glycan structure</th>
              </tr>
            </thead>
            <tbody>
              ${this.paragraph
            ? this.paragraph.lines.map(line => this.showLine(line))
            : html ``}
            </tbody>
          </table>
        </div>
      </div>
    `;
    }
};
GlyCombInputTableGlycoprotein.styles = style.cssResult;
GlyCombInputTableGlycoprotein = __decorate([
    customElement('glycomb-input-table-glycoprotein')
], GlyCombInputTableGlycoprotein);
export { GlyCombInputTableGlycoprotein };
