import { html } from 'lit';
import { PageElement } from '@/js/pages/base/page-element';
import { isLoggedIn, redirectTo } from '@/js/utilities/auth';
import { getGoogleSigninPath } from '@/js/utilities/url';
export class AuthorizationRequiredPageElement extends PageElement {
    constructor() {
        super();
        // If the user is not logged in,
        // then redirect to google signin page immediately.
        if (!isLoggedIn()) {
            redirectTo(getGoogleSigninPath());
        }
    }
    render() {
        return isLoggedIn()
            ? this.renderIfLoggedIn()
            : html `
          <section class="section">
            <h1 class="title is-4">Redirecting to login form...</h1>
          </section>
        `;
    }
}
