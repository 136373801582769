var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombNavbar_1;
import { LitElement, html } from 'lit';
import { customElement, query, queryAll } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { getGoogleSigninPath } from '@/js/utilities/url';
import { isLoggedIn } from '@/js/utilities/auth';
import { isCookiePolicyAccepted } from '@/js/utilities/cookie';
import { handleGlyCombError } from '@/js/utilities/error_handling';
import { GlyCombBrowserLocalError } from '@/js/types/error_handling';
import glycombLogo from '@/assets/images/glycomb_logo.png';
const style = await import('@/js/style');
let GlyCombNavbar = GlyCombNavbar_1 = class GlyCombNavbar extends LitElement {
    constructor() {
        super(...arguments);
        this.closeDropdownsOnBodyClick = () => {
            this.dropdowns.forEach(dropdown => {
                dropdown.classList.remove('is-active');
            });
        };
    }
    static async isCookieAccepted() {
        const errcode = 'gcb-frontendE001';
        const result = await handleGlyCombError(async () => {
            const isAccepted = isCookiePolicyAccepted();
            if (!isAccepted) {
                throw new GlyCombBrowserLocalError('', errcode);
            }
            return isAccepted;
        });
        return Option.getOrElse(() => false)(result);
    }
    firstUpdated() {
        this.burger.addEventListener('click', _ => {
            this.burger.classList.toggle('is-active');
            this.navbarMenu.classList.toggle('is-active');
        });
        this.dropdowns.forEach(dropdown => {
            dropdown.addEventListener('click', event => {
                event.stopPropagation();
                dropdown.classList.toggle('is-active');
                this.dropdowns.forEach(other => {
                    if (other !== dropdown) {
                        other.classList.remove('is-active');
                    }
                });
            });
        });
    }
    connectedCallback() {
        if (super.connectedCallback) {
            super.connectedCallback();
        }
        document
            .querySelector('body')
            .addEventListener('click', this.closeDropdownsOnBodyClick);
    }
    disconnectedCallback() {
        document
            .querySelector('body')
            .removeEventListener('click', this.closeDropdownsOnBodyClick);
        if (super.disconnectedCallback) {
            super.disconnectedCallback();
        }
    }
    render() {
        return html `
      <nav class="navbar is-fixed-top has-shadow is-primary">
        <div class="navbar-brand">
          <a class="navbar-item" href="./">
            <img
              alt="GlyComb logo"
              title="GlyComb: a repository for glycoconjugate information, including glycopeptides, glycoproteins, glycolipids and glycosides"
              src="${glycombLogo}"
            />
            <span class="is-size-3 has-text-light-grey">GlyComb</span>
          </a>

          <div class="navbar-burger burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item is-size-5" href="./registration"
              >Registration</a
            >

            <a class="navbar-item is-size-5" href="./search">Search</a>

            <div class="navbar-item is-size-5 has-dropdown">
              <a class="navbar-link" href="#">Browse</a>
              <div class="navbar-dropdown">
                <a class="navbar-item" href="./glycopeptide-accession"
                  >Glycopeptide List</a
                >
                <a class="navbar-item" href="./glycoprotein-accession"
                  >Glycoprotein List</a
                >
              </div>
            </div>

            <div class="navbar-item is-size-5 has-dropdown">
              <a class="navbar-link" href="#">Tools</a>
              <div class="navbar-dropdown">
                <a
                  class="navbar-item"
                  href="./byonic-summary-worksheet-converter"
                  >Byonic summary worksheet converter</a
                >
              </div>
            </div>

            ${isLoggedIn()
            ? html `
                  <a class="navbar-item is-size-5" href="./user_profile"
                    >User Profile</a
                  >
                `
            : html ``}

            <a
              class="navbar-item is-size-5"
              target="_blank"
              rel="noopener noreferrer"
              href="https://glycosmos.org/feedback"
              >Feedback</a
            >
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    ${isLoggedIn()
            ? html `
                          <a
                            class="button is-danger is-rounded"
                            href="./logout"
                          >
                            <span class="icon">
                              <glycomb-fa-icon
                                icon="fas fa-sign-out-alt"
                                size="is-5"
                              ></glycomb-fa-icon>
                            </span>
                            <span>Logout</span>
                          </a>
                        `
            : html `
                          <glycomb-google-signin-button
                            theme="light"
                            href="${getGoogleSigninPath()}"
                            .validationAsync=${GlyCombNavbar_1.isCookieAccepted}
                          >
                          </glycomb-google-signin-button>
                        `}
                  </div>
                  <div class="level-item">
                    <glycomb-search-bar></glycomb-search-bar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    `;
    }
};
GlyCombNavbar.styles = style.cssResult;
__decorate([
    query('.burger')
], GlyCombNavbar.prototype, "burger", void 0);
__decorate([
    query('.navbar-menu')
], GlyCombNavbar.prototype, "navbarMenu", void 0);
__decorate([
    queryAll('.has-dropdown')
], GlyCombNavbar.prototype, "dropdowns", void 0);
GlyCombNavbar = GlyCombNavbar_1 = __decorate([
    customElement('glycomb-navbar')
], GlyCombNavbar);
export { GlyCombNavbar };
