import camelcaseKeys from 'camelcase-keys';
export function toLowerCamelCase(obj) {
    return camelcaseKeys(obj, { deep: true });
}
export function truncateLeading(str, maxLength) {
    return str.substring(str.length - maxLength);
}
export function truncateTrailing(str, maxLength) {
    return str.substring(0, maxLength);
}
