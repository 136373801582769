var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import * as Either from 'fp-ts/Either';
import { getServer } from '@/js/utilities/url';
import { showLongAminoAcidSequence } from '@/js/utilities/view_helper';
import { safeFetch, handleGlyCombError } from '@/js/utilities/error_handling';
export const GlyCombProteinSequenceMixin = (superClass) => {
    class GlyCombProteinSequenceMixinClass extends superClass {
        constructor() {
            super(...arguments);
            this.uniprotEntryList = [];
            this.downloadingIdList = [];
        }
        async fetchSequence(uniprotId) {
            const idx = this.downloadingIdList.findIndex(id => id === uniprotId);
            if (idx < 0) {
                this.downloadingIdList.push(uniprotId);
                // UniProt API
                // const url = `https://rest.uniprot.org/uniprotkb/${uniprotId}.json`;
                // GlyCosmos API
                const server = getServer();
                const url = `https://api.${server}/upid2seq?upid=${uniprotId}`;
                const seqOpt = await handleGlyCombError(async () => {
                    const responseEither = await safeFetch(url, { method: 'GET' });
                    // Report error if exists
                    if (Either.isLeft(responseEither)) {
                        throw responseEither.left;
                    }
                    // UniProt API
                    // const result = await responseEither.right.json();
                    // return result.sequence.value;
                    // GlyCosmos API
                    const result = await responseEither.right.text();
                    return result;
                });
                if (Option.isSome(seqOpt)) {
                    // Success
                    this.uniprotEntryList = [
                        {
                            id: uniprotId,
                            seq: seqOpt.value,
                        },
                        ...this.uniprotEntryList,
                    ];
                }
                else {
                    // Error
                    this.uniprotEntryList = [
                        {
                            id: uniprotId,
                            seq: '',
                        },
                        ...this.uniprotEntryList,
                    ];
                }
            }
        }
        showSequence(uniprotId, site) {
            const idx = this.uniprotEntryList.findIndex(e => e.id === uniprotId);
            if (idx < 0) {
                return html `
          <div
            class="ball-clip-rotate glycomb-registration-uniprot-loader has-text-centered"
          >
            <div></div>
          </div>
        `;
            }
            const sequence = this.uniprotEntryList[idx].seq;
            if (sequence.length === 0) {
                // Sequence download finished but the content is empty...
                return html `Failed to fetch the sequence of "${uniprotId}"`;
            }
            return html `
        <pre
          class="glycomb-registration-code-area"
        ><code>${showLongAminoAcidSequence(sequence, [site])}</code></pre>
      `;
        }
    }
    __decorate([
        property({ type: Array })
    ], GlyCombProteinSequenceMixinClass.prototype, "uniprotEntryList", void 0);
    return GlyCombProteinSequenceMixinClass;
};
