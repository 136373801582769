var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombUserProfile_1;
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as jwtDecode from 'jwt-decode';
import * as Option from 'fp-ts/Option';
import izitoast from 'izitoast';
import copyToClipboard from 'clipboard-copy';
import { getBaseUrl, getGoogleSigninPath, getSubmissionsListApiPath, userProfileApiPath, } from '@/js/utilities/url';
import { getApiKey } from '@/js/utilities/cookie';
import { redirectTo } from '@/js/utilities/auth';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { showDate, showEmail, showPublishStatus, } from '@/js/utilities/view_helper';
import { AuthorizationRequiredPageElement } from '@/js/pages/base/authorization-required-page-element';
import { GlyCombPaginationMixin } from '@/js/pages/mixins/glycomb-pagination-mixin';
import config from '@/js/config';
import { GlyCombMultiplePublishConfirmationOverlay } from '@/js/widgets/glycomb-multiple-publish-confirmation-overlay';
const GlyCombPaginationBase = GlyCombPaginationMixin(AuthorizationRequiredPageElement);
const style = await import('@/js/style');
let GlyCombUserProfile = GlyCombUserProfile_1 = class GlyCombUserProfile extends GlyCombPaginationBase {
    constructor() {
        super(...arguments);
        this.apiPath = getSubmissionsListApiPath();
        this.datatype = 'Glycopeptide';
        this.order = 'reception_date';
        this.authorizationRequired = true;
        this.userProfileOpt = Option.none;
    }
    async updateContentList() {
        const apiKey = getApiKey();
        if (Option.isSome(apiKey)) {
            super.updateContentList();
        }
        else {
            redirectTo(getGoogleSigninPath());
        }
    }
    async firstUpdated() {
        await this.getUserProfile();
        await this.updatePagination(1);
    }
    static displayApiKey() {
        return Option.getOrElse(() => '(Not found. Maybe the API key was expired.)')(getApiKey());
    }
    static displayExpirationDate() {
        const apiKeyOpt = getApiKey();
        if (Option.isSome(apiKeyOpt)) {
            const decodedJwt = jwtDecode.default(apiKeyOpt.value);
            // Convert seconds to milliseconds.
            const d = new Date(decodedJwt.exp * 1000);
            const zerofill = (n) => n.toString().padStart(2, '0');
            const year = d.getFullYear();
            const month = zerofill(d.getMonth() + 1);
            const date = zerofill(d.getDate());
            const hour = zerofill(d.getHours());
            const minute = zerofill(d.getMinutes());
            const second = zerofill(d.getSeconds());
            return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
        }
        return '---';
    }
    static copyToClipboardButtonClickHandler(_) {
        const apiKey = getApiKey();
        if (Option.isSome(apiKey)) {
            copyToClipboard(apiKey.value);
            // Show "Copied to your clipboard!" message to the user.
            izitoast.info({
                title: 'Info',
                message: 'Copied to your clipboard!',
                position: 'topRight',
                timeout: config.toast_notification_timeout,
            });
        }
    }
    static showSubmissionNumbersForm() {
        GlyCombMultiplePublishConfirmationOverlay.showOverlay(() => {
            // Refresh
            window.location.reload();
        });
    }
    async getUserProfile() {
        const apiKey = getApiKey();
        if (Option.isSome(apiKey)) {
            const responseOpt = await fetchFromGlyCombApi({
                url: `${getBaseUrl()}${userProfileApiPath()}`,
                method: 'GET',
                contentType: 'application/json',
                authorizationRequired: true,
            });
            this.userProfileOpt = Option.map(response => response.contents)(responseOpt);
        }
        else {
            redirectTo(getGoogleSigninPath());
        }
    }
    renderIfLoggedIn() {
        return html `
      <section class="section">
        <div class="container">
          <div class="block">
            <h1 class="title">User Profile</h1>

            <fieldset>
              <div class="field">
                <label class="label is-medium">Registration Number</label>
                <div class="control has-icons-left">
                  <input class="input is-medium"
                         type="text"
                         readonly=true
                         .value="${Option.getOrElse(() => '-')(Option.map(up => up.registrationNumber)(this.userProfileOpt))}">
                  </input>
                  <span class="icon is-medium is-left">
                    <glycomb-fa-icon icon="fas fa-user" size="is-5"></glycomb-fa-icon>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label is-medium">Registration Date</label>
                <div class="control has-icons-left">
                  <input class="input is-medium"
                         type="text"
                         readonly=true
                         .value="${Option.getOrElse(() => '-')(Option.map(up => up.registrationDate)(this.userProfileOpt))}">
                  </input>
                  <span class="icon is-medium is-left">
                    <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label is-medium">Primary E-mail address</label>
                <div class="control has-icons-left">
                  <input class="input is-medium"
                         type="email"
                         readonly=true
                         .value="${showEmail(Option.map(up => up.primaryMail)(this.userProfileOpt))}">
                  </input>
                  <span class="icon is-medium is-left">
                    <glycomb-fa-icon icon="fas fa-envelope" size="is-5"></glycomb-fa-icon>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label is-medium">Secondary E-mail address</label>
                <div class="control has-icons-left">
                  <input class="input is-medium"
                         type="email"
                         readonly=true
                         .value="${showEmail(Option.map(up => up.secondlyMail)(this.userProfileOpt))}">
                  </input>
                  <span class="icon is-medium is-left">
                    <glycomb-fa-icon icon="fas fa-envelope" size="is-5"></glycomb-fa-icon>
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label is-medium">API Key</label>

                <div class="glycomb-float-button-wrapper">
                  <div class="control has-icons-left">
                    <input class="input is-medium"
                           type="text"
                           readonly=true
                           .value="${GlyCombUserProfile_1.displayApiKey()}">
                    </input>
                    <span class="icon is-medium is-left">
                      <glycomb-fa-icon icon="fas fa-key" size="is-5"></glycomb-fa-icon>
                    </span>
                  </div>
                  ${Option.isSome(getApiKey())
            ? html `
                          <button
                            class="button is-info glycomb-float-button"
                            @click=${GlyCombUserProfile_1.copyToClipboardButtonClickHandler}
                          >
                            <span class="icon"
                              ><glycomb-fa-icon
                                icon="fas fa-copy"
                                size="is-6"
                              ></glycomb-fa-icon></span
                            ><span>Copy</span>
                          </button>
                        `
            : ''}
                </div>
              </div>
              <div class="field">
                <label class="label is-medium">Expiration Date</label>
                <div class="control has-icons-left">
                  <input class="input is-medium"
                         type="text"
                         readonly=true
                         .value="${GlyCombUserProfile_1.displayExpirationDate()}">
                  </input>
                  <span class="icon is-medium is-left">
                    <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                  </span>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="block">
            <h2 class="title">User Submission List</h2>

            <div class="tabs is-boxed is-left glycomb-float-button-wrapper">
              <ul>
                <li
                  id="glycopeptides"
                  class="${this.datatype === 'Glycopeptide' ? 'is-active' : ''}"
                >
                  <a href="#"
                     @click=${(e) => {
            e.preventDefault();
            this.updateDataType('Glycopeptide');
        }}
                   >Glycopeptides</a>
                </li>
                <li
                  id="glycoproteins"
                  class="${this.datatype === 'Glycoprotein' ? 'is-active' : ''}"
                >
                  <a href="#"
                     @click=${(e) => {
            e.preventDefault();
            this.updateDataType('Glycoprotein');
        }}
                  >Glycoproteins</a>
                </li>
              </ul>

              <div class="glycomb-float-button-fixed">
                <button
                  class="button is-success"
                  @click=${GlyCombUserProfile_1.showSubmissionNumbersForm}
                >
                  <span class="icon"
                    ><glycomb-fa-icon
                      icon="fas fa-lock-open"
                      size="is-6"
                    ></glycomb-fa-icon></span
                  ><span>Open to the public</span>
                </button>
              </div>
            </div>

            <div class="table-container">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th class="glycomb-pagination-sorting-header"
                        @click=${(_) => this.updateSortOrder('reception_number')}>
                      Submission Number ${this.getSortOrderArrow('reception_number')}
                    </th>
                    <th class="glycomb-pagination-sorting-header"
                        @click=${(_) => this.updateSortOrder('reception_date')}>
                      Submission Date ${this.getSortOrderArrow('reception_date')}
                    </th>
                    <th class="glycomb-pagination-sorting-header"
                        @click=${(_) => this.updateSortOrder('accession')}>
                      Accession Number ${this.getSortOrderArrow('accession')}
                    </th>
                    <th class="glycomb-pagination-sorting-header"
                        @click=${(_) => this.updateSortOrder('accession_date')}>
                      Registration Date ${this.getSortOrderArrow('accession_date')}
                    </th>
                    <th>Status</th>
                    <th>Publication Date</th>
                  </tr>
                </thead>
                <tbody>
                  ${this.contentList.map(submission => html `
                        <tr>
                          <td>
                            <a
                              href=${`/${submission.datatype.toLowerCase()}-submission?reception_number=${submission.receptionNumber}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              ${submission.receptionNumber}
                            </a>
                          </td>
                          <td>${showDate(submission.receptionDate)}</td>
                          <td>
                            ${submission.publicationFlag
            ? html `<a
                                  href=${`/${submission.datatype.toLowerCase()}-entry?accession_number=${submission.accession}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  ${submission.accession}
                                </a>`
            : submission.accession
                ? html `${submission.accession}`
                : 'Pending'}
                          </td>
                          <td>${showDate(submission.accessionDate)}</td>
                          <td>
                            ${showPublishStatus(submission.publicationFlag, submission.accessionFlag, submission.accessionLog)}
                          </td>
                          <td>${showDate(submission.publicationDate)}</td>
                        </tr>
                      `)}
                </tbody>
              </table>
            </div>

            <glycomb-pagination currentPage=${this.currentPage}
                                lastPage=${this.lastPage}
                                .updatePagination=${(i) => this.updatePagination(i)}>
            </glycomb-pagination>
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombUserProfile.styles = style.cssResult;
__decorate([
    property({ type: Object })
], GlyCombUserProfile.prototype, "userProfileOpt", void 0);
GlyCombUserProfile = GlyCombUserProfile_1 = __decorate([
    customElement('glycomb-user-profile')
], GlyCombUserProfile);
export { GlyCombUserProfile };
