var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageElement } from '@/js/pages/base/page-element';
const style = await import('@/js/style');
let GlyCombByonicConverter = class GlyCombByonicConverter extends PageElement {
    constructor() {
        super(...arguments);
        this.convertStep = 0;
        this.inputFileName = 'empty';
        this.comberterResultOpt = undefined;
    }
    byonicConverterStateChangedHandler(e) {
        // Scroll to top of the page on the state change.
        window.scrollTo(0, 0);
        if (e.detail.nextStep === 0) {
            this.comberterResultOpt = undefined;
            this.inputFileName = 'empty';
        }
        else if (e.detail.nextStep === 1) {
            this.comberterResultOpt = e.detail.value;
            this.inputFileName = e.detail.fileName;
        }
        this.convertStep = e.detail.nextStep;
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <h1 class="title">Byonic summary worksheet converter</h1>
          <glycomb-byonic-converter-config
            .hidden=${this.convertStep !== 0}
            @byonic-converter-state-changed=${this
            .byonicConverterStateChangedHandler}
          ></glycomb-byonic-converter-config>
          <glycomb-byonic-converter-result
            .hidden=${this.convertStep !== 1}
            .inputFileName=${this.inputFileName}
            .comberterResultOpt=${this.comberterResultOpt}
            @byonic-converter-state-changed=${this
            .byonicConverterStateChangedHandler}
          ></glycomb-byonic-converter-result>
        </div>
      </section>
    `;
    }
};
GlyCombByonicConverter.styles = style.cssResult;
__decorate([
    property({ type: Number })
], GlyCombByonicConverter.prototype, "convertStep", void 0);
__decorate([
    property({ type: String })
], GlyCombByonicConverter.prototype, "inputFileName", void 0);
__decorate([
    property({ type: Object })
], GlyCombByonicConverter.prototype, "comberterResultOpt", void 0);
GlyCombByonicConverter = __decorate([
    customElement('glycomb-byonic-converter')
], GlyCombByonicConverter);
export { GlyCombByonicConverter };
