var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { range } from '@glycomb/glycomb-utils';
const style = await import('@/js/style');
let GlyCombPagination = class GlyCombPagination extends LitElement {
    constructor() {
        super(...arguments);
        this.currentPage = 0;
        this.lastPage = 0;
    }
    getPaginationList() {
        const numbers = 
        // Pagination list will be [i-2, i-1, i, i+1, i+2]
        range(this.currentPage - 2, this.currentPage + 3)
            // If i is 1 or 2, then offset them to start with 1.
            .map(i => this.currentPage === 1 ? i + 2 : this.currentPage === 2 ? i + 1 : i)
            // If i is lastPage or lastPage - 1, then offset them to end with lastPage.
            .map(i => this.currentPage === this.lastPage
            ? i - 2
            : this.currentPage === this.lastPage - 1
                ? i - 1
                : i)
            .filter(i => i >= 1 && i <= this.lastPage);
        const mainLiItems = numbers.map(i => i === this.currentPage
            ? html `
            <li>
              <button
                class="pagination-link is-current glycomb-button-centered"
                aria-label="Page ${this.currentPage}"
                aria-current="page"
              >
                ${this.currentPage}
              </button>
            </li>
          `
            : html `
            <li>
              <button
                class="pagination-link glycomb-button-centered"
                aria-label="Page ${i}"
                @click=${(_) => this.updatePagination(i)}
              >
                ${i}
              </button>
            </li>
          `);
        const leftItems = numbers.length !== 0 && numbers[0] !== 1
            ? html `
            <li>
              <button
                class="pagination-link glycomb-button-centered"
                aria-label="Page 1"
                @click=${(_) => this.updatePagination(1)}
              >
                1
              </button>
            </li>
            <li>
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
          `
            : html ``;
        const rightItems = numbers.length !== 0 && numbers[numbers.length - 1] !== this.lastPage
            ? html `
            <li>
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <button
                class="pagination-link glycomb-button-centered"
                aria-label="Page ${this.lastPage}"
                @click=${(_) => this.updatePagination(this.lastPage)}
              >
                ${this.lastPage}
              </button>
            </li>
          `
            : html ``;
        return html `
      <ul class="pagination-list">
        ${leftItems} ${mainLiItems} ${rightItems}
      </ul>
    `;
    }
    render() {
        return html `
      <nav
        class="pagination is-centered glycomb-pagination"
        role="navigation"
        aria-label="pagination"
      >
        <button
          class="pagination-previous glycomb-button-centered"
          ?disabled=${this.currentPage <= 1}
          @click=${(_) => this.currentPage > 1
            ? this.updatePagination(this.currentPage - 1)
            : undefined}
        >
          Previous
        </button>
        <button
          class="pagination-next glycomb-button-centered"
          ?disabled=${this.currentPage >= this.lastPage}
          @click=${(_) => this.currentPage < this.lastPage
            ? this.updatePagination(this.currentPage + 1)
            : undefined}
        >
          Next
        </button>
        ${this.getPaginationList()}
      </nav>
    `;
    }
};
GlyCombPagination.styles = style.cssResult;
__decorate([
    property({ type: Number })
], GlyCombPagination.prototype, "currentPage", void 0);
__decorate([
    property({ type: Number })
], GlyCombPagination.prototype, "lastPage", void 0);
GlyCombPagination = __decorate([
    customElement('glycomb-pagination')
], GlyCombPagination);
export { GlyCombPagination };
