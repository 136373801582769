var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombLogin_1;
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import * as jwtDecode from 'jwt-decode';
import { setCookieEntry, unsetCookieEntry } from '@/js/utilities/cookie';
import { redirectTo } from '@/js/utilities/auth';
import { PageElement } from '@/js/pages/base/page-element';
import config from '@/js/config';
import { getUrlQueryParameter } from '@/js/utilities/url';
const style = await import('@/js/style');
let GlyCombLogin = GlyCombLogin_1 = class GlyCombLogin extends PageElement {
    constructor() {
        super(...arguments);
        this.success = true;
    }
    static setTokenOrErrcode(tokenOpt, errcodeOpt) {
        // If the user access this page without authenticate process, redirect to top page immediately.
        if (Option.isNone(tokenOpt) && Option.isNone(errcodeOpt)) {
            return true;
        }
        // Remove existing token & errcode.
        unsetCookieEntry(config.cookie.jwt_api_key);
        unsetCookieEntry(config.cookie.error_code_key);
        // If there is some text in errcode parameter,
        // then login process will fail.
        if (Option.isNone(errcodeOpt) || errcodeOpt.value === '') {
            try {
                const token = Option.getOrElse(() => '')(tokenOpt);
                // Extract the expire date.
                const decodedJwt = jwtDecode.default(token);
                // Convert seconds to milliseconds
                const expireDate = new Date(decodedJwt.exp * 1000);
                // Save the token as cookie with expire time.
                setCookieEntry(config.cookie.jwt_api_key, token, '/', Option.some(expireDate));
                setCookieEntry(config.cookie.login_check_key, config.cookie.login_check_value, '/', Option.none);
            }
            catch (e) {
                return false;
            }
        }
        else {
            // Save the errcode as cookie.
            setCookieEntry(config.cookie.error_code_key, errcodeOpt.value, '/', Option.none);
        }
        return true;
    }
    firstUpdated() {
        const tokenOpt = getUrlQueryParameter(config.cookie.jwt_api_key);
        const errcodeOpt = getUrlQueryParameter(config.cookie.error_code_key);
        this.success = GlyCombLogin_1.setTokenOrErrcode(tokenOpt, errcodeOpt);
        if (this.success) {
            // Redirect to top page
            redirectTo('./');
        }
    }
    render() {
        return this.success
            ? html `<div class="block">Please wait...</div>`
            : html `
          <div class="block">
            Unknown error occurred during the login process. Please report this
            error to your system administrator!
          </div>
          <div class="block">URL: ${document.location.toString()}</div>
        `;
    }
};
GlyCombLogin.styles = style.cssResult;
__decorate([
    property({ type: Boolean })
], GlyCombLogin.prototype, "success", void 0);
GlyCombLogin = GlyCombLogin_1 = __decorate([
    customElement('glycomb-login')
], GlyCombLogin);
export { GlyCombLogin };
