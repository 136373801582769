var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
const style = await import('@/js/style');
let GlyCombLoadingOverlay = class GlyCombLoadingOverlay extends LitElement {
    render() {
        return html `
      <div class="pageloader is-active is-light">
        <span class="title">Loading...</span>
      </div>
    `;
    }
};
GlyCombLoadingOverlay.styles = style.cssResult;
GlyCombLoadingOverlay = __decorate([
    customElement('glycomb-loading-overlay')
], GlyCombLoadingOverlay);
export { GlyCombLoadingOverlay };
const loadingOverlay = document.createElement('glycomb-loading-overlay');
export const showLoadingOverlay = () => {
    document.body.appendChild(loadingOverlay);
};
export const hideLoadingOverlay = () => {
    document.body.removeChild(loadingOverlay);
};
