var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombRegistrationCompleted_1;
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import izitoast from 'izitoast';
import copyToClipboard from 'clipboard-copy';
import * as Option from 'fp-ts/Option';
import { handleGlyCombError } from '@/js/utilities/error_handling';
import { GlyCombBrowserLocalError } from '@/js/types/error_handling';
import config from '@/js/config';
const style = await import('@/js/style');
let GlyCombRegistrationCompleted = GlyCombRegistrationCompleted_1 = class GlyCombRegistrationCompleted extends LitElement {
    constructor() {
        super(...arguments);
        this.registrationRequestResponses = [];
    }
    static copyContentToClipboard(content) {
        copyToClipboard(content);
        // Show "Copied to your clipboard!" message to the user.
        izitoast.info({
            title: 'Info',
            message: 'Copied to your clipboard!',
            position: 'topRight',
            timeout: config.toast_notification_timeout,
        });
    }
    static async getSubmissionNumber(response) {
        const submissionNumberOpt = await handleGlyCombError(async () => {
            if (response.errcode === '') {
                return response.contents;
            }
            throw new GlyCombBrowserLocalError('', response.errcode);
        });
        if (Option.isSome(submissionNumberOpt)) {
            return submissionNumberOpt.value;
        }
        return `An error occurred during the process. (errcode: ${response.errcode})`;
    }
    getNotificationMessage() {
        if (this.registrationRequestResponses.every(r => r.errcode === '')) {
            return html `
        <div class="notification is-success is-light">
          <span class="icon">
            <glycomb-fa-icon icon="fas fa-check" size="is-6"></glycomb-fa-icon>
          </span>
          <span>All your requests were accepted successfully.</span>
        </div>
      `;
        }
        return html `
      <div class="notification is-warning is-light">
        <span class="icon">
          <glycomb-fa-icon
            icon="fas fa-exclamation-triangle"
            size="is-6"
          ></glycomb-fa-icon>
        </span>
        <span>Some requests were not accepted properly.</span>
      </div>
    `;
    }
    async copyButtonClickHandler(e) {
        e.preventDefault();
        const submissionNumberList = await Promise.all(this.registrationRequestResponses.map(response => GlyCombRegistrationCompleted_1.getSubmissionNumber(response)));
        const submissionNumbers = submissionNumberList.join('\n');
        GlyCombRegistrationCompleted_1.copyContentToClipboard(submissionNumbers);
    }
    async saveAsButtonClickHandler(e) {
        e.preventDefault();
        const submissionNumberList = await Promise.all(this.registrationRequestResponses.map(response => GlyCombRegistrationCompleted_1.getSubmissionNumber(response)));
        const submissionNumbers = submissionNumberList.join('\n');
        const blob = new Blob([submissionNumbers], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'submission_numbers.txt';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
    }
    render() {
        return html `
      ${this.getNotificationMessage()}

      <div class="box">
        <div class="content is-normal glycomb-float-button-wrapper">
          <p>Your submission numbers are:</p>
          <ul>
            ${this.registrationRequestResponses.map(response => html `
                  <li>
                    <b>
                      ${until(GlyCombRegistrationCompleted_1.getSubmissionNumber(response), '<span>Loading</span>')}
                    </b>
                  </li>
                `)}
          </ul>

          <div class="glycomb-float-button">
            <div class="is-flex is-flex-direction-column" style="gap: 0.25em;">
              <button
                class="button is-info"
                @click=${this.copyButtonClickHandler}
              >
                <span class="icon"
                  ><glycomb-fa-icon
                    icon="fas fa-copy"
                    size="is-6"
                  ></glycomb-fa-icon></span
                ><span>Copy</span>
              </button>

              <button
                class="button is-primary"
                @click=${this.saveAsButtonClickHandler}
              >
                <span class="icon"
                  ><glycomb-fa-icon
                    icon="fas fa-file-download"
                    size="is-6"
                  ></glycomb-fa-icon></span
                ><span>Save As</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `;
    }
};
GlyCombRegistrationCompleted.styles = style.cssResult;
__decorate([
    property({ type: Array })
], GlyCombRegistrationCompleted.prototype, "registrationRequestResponses", void 0);
GlyCombRegistrationCompleted = GlyCombRegistrationCompleted_1 = __decorate([
    customElement('glycomb-registration-completed')
], GlyCombRegistrationCompleted);
export { GlyCombRegistrationCompleted };
