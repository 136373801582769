import * as Option from 'fp-ts/Option';
import * as Either from 'fp-ts/Either';
import { getApiKey } from '@/js/utilities/cookie';
import { handleGlyCombError, safeFetch } from '@/js/utilities/error_handling';
import { toLowerCamelCase } from '@/js/utilities/miscellaneous';
import { GlyCombBrowserLocalError } from '@/js/types/error_handling';
export async function fetchFromGlyCombApi(params) {
    const errcode = 'gcb-frontendE002';
    const apiKeyOpt = params.authorizationRequired ? getApiKey() : Option.none;
    return handleGlyCombError(async () => {
        if (params.authorizationRequired && Option.isNone(apiKeyOpt)) {
            throw new GlyCombBrowserLocalError('', errcode);
        }
        const responseEither = await safeFetch(params.url, {
            method: params.method,
            headers: {
                Accept: 'application/json',
                // Insert contentType if exists
                ...(params.contentType && { 'Content-Type': params.contentType }),
                // Insert authorization key if required
                ...(Option.isSome(apiKeyOpt) && {
                    Authorization: `Bearer ${apiKeyOpt.value}`,
                }),
            },
            // Insert body value if exists
            ...(params.body && { body: params.body }),
        });
        // Report error if exists
        if (Either.isLeft(responseEither)) {
            throw responseEither.left;
        }
        const resultJson = await responseEither.right.json();
        return toLowerCamelCase(resultJson);
    });
}
