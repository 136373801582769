var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombByonicConverterResult_1;
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import izitoast from 'izitoast';
import copyToClipboard from 'clipboard-copy';
import config from '@/js/config';
const style = await import('@/js/style');
let GlyCombByonicConverterResult = GlyCombByonicConverterResult_1 = class GlyCombByonicConverterResult extends LitElement {
    constructor() {
        super(...arguments);
        this.inputFileName = 'empty';
        this._comberterResult = GlyCombByonicConverterResult_1.emptyResult;
    }
    static copyContentToClipboard(content) {
        copyToClipboard(content);
        // Show "Copied to your clipboard!" message to the user.
        izitoast.info({
            title: 'Info',
            message: 'Copied to your clipboard!',
            position: 'topRight',
            timeout: config.toast_notification_timeout,
        });
    }
    get comberterResultOpt() {
        return this._comberterResult;
    }
    set comberterResultOpt(newVal) {
        const oldVal = this._comberterResult;
        if (newVal) {
            this._comberterResult = newVal;
        }
        else {
            this._comberterResult = GlyCombByonicConverterResult_1.emptyResult;
        }
        this.requestUpdate('comberterResultOpt', oldVal);
    }
    emitEvent() {
        this.dispatchEvent(new CustomEvent(config.pages.byonic_converter.state_change_event_name, {
            bubbles: true,
            composed: true,
            detail: {
                nextStep: 0,
            },
        }));
    }
    getConversionResult() {
        return this.comberterResultOpt.result.result.length > 0
            ? this.comberterResultOpt.result.result
            : 'No valid output was generated. Please read the error messages for more details if they are displayed.';
    }
    backButtonClickHandler(_) {
        this.emitEvent();
    }
    resultCopyButtonClickHandler(e) {
        e.preventDefault();
        const content = this.getConversionResult();
        GlyCombByonicConverterResult_1.copyContentToClipboard(content);
    }
    wurcsCopyButtonClickHandler(e) {
        e.preventDefault();
        const content = this.comberterResultOpt.wurcsResult.result.join('\n');
        GlyCombByonicConverterResult_1.copyContentToClipboard(content);
    }
    saveAsButtonClickHandler(e) {
        e.preventDefault();
        const content = this.getConversionResult();
        const blob = new Blob([content], { type: 'text/tab-separated-values' });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = `converted_${this.inputFileName}.tsv`;
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
    }
    render() {
        return html `
      <div class="columns is-multiline">
        <div class="column is-12-tablet">
          <nav class="panel is-success">
            <p class="panel-heading">Conversion result</p>
            <div class="panel-block">
              <div class="glycomb-float-button-wrapper" style="width: 100%;">
                <textarea
                  class="textarea"
                  rows="15"
                  placeholder="The converted content will be shown here..."
                >
${this.getConversionResult()}</textarea
                >
                <div class="glycomb-float-button">
                  <div
                    class="is-flex is-flex-direction-column"
                    style="gap: 0.25em;"
                  >
                    <button
                      class="button is-info"
                      @click=${this.resultCopyButtonClickHandler}
                    >
                      <span class="icon"
                        ><glycomb-fa-icon
                          icon="fas fa-copy"
                          size="is-6"
                        ></glycomb-fa-icon></span
                      ><span>Copy</span>
                    </button>

                    <button
                      class="button is-primary"
                      @click=${this.saveAsButtonClickHandler}
                    >
                      <span class="icon"
                        ><glycomb-fa-icon
                          icon="fas fa-file-download"
                          size="is-6"
                        ></glycomb-fa-icon></span
                      ><span>Save As</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>

        ${this.comberterResultOpt.wurcsResult.result.length > 0
            ? html `
              <div class="column is-12-tablet">
                <nav class="panel is-info">
                  <p class="panel-heading">Notifications</p>
                  <div class="panel-block is-flex-direction-column">
                    <article class="message is-info" style="width: 100%;">
                      <div class="message-body">
                        During the conversion, the glycan structures which does
                        not have the corresponding GlyTouCan ID yet were found.
                        Please register the following WURCS-formatted glycan
                        structures representing such glycans to GlyTouCan
                        <strong
                          ><a
                            href="https://glytoucan.org/Users/registries"
                            target="_blank"
                            rel="noopener noreferrer"
                            >here</a
                          ></strong
                        >
                        before submitting the converted result to GlyComb.
                      </div>
                    </article>

                    <div
                      class="glycomb-float-button-wrapper"
                      style="width: 100%;"
                    >
                      <textarea
                        class="textarea"
                        rows="15"
                        placeholder="The glycan structures which are not registered to GlyTouCan will be shown here..."
                      >
${this.comberterResultOpt.wurcsResult.result.join('\n')}</textarea
                      >

                      <div class="glycomb-float-button">
                        <button
                          class="button is-info"
                          @click=${this.wurcsCopyButtonClickHandler}
                        >
                          <span class="icon"
                            ><glycomb-fa-icon
                              icon="fas fa-copy"
                              size="is-6"
                            ></glycomb-fa-icon></span
                          ><span>Copy</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            `
            : html ``}
        ${this.comberterResultOpt.result.errors.length > 0 ||
            this.comberterResultOpt.wurcsResult.errors.length > 0
            ? html `
              <div class="column is-12-tablet">
                <nav class="panel is-danger">
                  <p class="panel-heading">Error messages</p>
                  <div
                    class="panel-block is-flex-direction-column"
                    style="max-height: 50vh; overflow-y: scroll;"
                  >
                    ${this.comberterResultOpt.result.errors.map(e => html `
                          <article
                            class="message is-danger"
                            style="width: 100%;"
                          >
                            <div class="message-body">
                              <pre
                                class="glycomb-byonic-converter-code-area"
                              ><code>${e}</code></pre>
                            </div>
                          </article>
                        `)}
                    ${this.comberterResultOpt.wurcsResult.errors.map(e => html `
                          <article
                            class="message is-danger"
                            style="width: 100%;"
                          >
                            <div class="message-body">
                              <pre
                                class="glycomb-byonic-converter-code-area"
                              ><code>${e}</code></pre>
                            </div>
                          </article>
                        `)}
                  </div>
                </nav>
              </div>
            `
            : html ``}
      </div>

      <div class="columns">
        <div class="column is-12-tablet">
          <div class="buttons is-centered">
            <button
              class="button is-medium is-danger"
              @click=${this.backButtonClickHandler}
            >
              <span class="icon">
                <glycomb-fa-icon
                  id="submitButtonIcon"
                  icon="fas fa-arrow-alt-circle-left"
                  size="is-4"
                ></glycomb-fa-icon>
              </span>
              <span>Back</span>
            </button>
          </div>
        </div>
      </div>
    `;
    }
};
GlyCombByonicConverterResult.styles = style.cssResult;
GlyCombByonicConverterResult.emptyResult = {
    result: {
        result: '',
        errors: [],
    },
    wurcsResult: {
        result: [],
        errors: [],
    },
};
__decorate([
    property({ type: String })
], GlyCombByonicConverterResult.prototype, "inputFileName", void 0);
__decorate([
    property({ type: Object })
], GlyCombByonicConverterResult.prototype, "comberterResultOpt", null);
GlyCombByonicConverterResult = GlyCombByonicConverterResult_1 = __decorate([
    customElement('glycomb-byonic-converter-result')
], GlyCombByonicConverterResult);
export { GlyCombByonicConverterResult };
