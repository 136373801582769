var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getBaseUrl } from '@/js/utilities/url';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
export const GlyCombPaginationMixin = (superClass) => {
    class GlyCombPaginationMixinClass extends superClass {
        constructor() {
            super(...arguments);
            this.limit = 10;
            this.desc = true;
            this.contentList = [];
            this.currentPage = 0;
            this.lastPage = 0;
        }
        generateApiUrl() {
            const base = getBaseUrl();
            const api = this.apiPath;
            const { datatype, limit, order, desc } = this;
            const offset = this.limit * (this.currentPage - 1);
            const params = [
                `datatype=${datatype}`,
                `limit=${limit}`,
                `offset=${offset}`,
                `order=${order}`,
                `desc=${desc}`,
            ];
            return `${base}${api}?${params.reduce((a, e) => `${a}&${e}`, '')}`;
        }
        async updateContentList() {
            const responseOpt = await fetchFromGlyCombApi({
                url: this.generateApiUrl(),
                method: 'GET',
                contentType: 'application/json',
                authorizationRequired: this.authorizationRequired,
            });
            if (Option.isSome(responseOpt)) {
                const response = responseOpt.value;
                this.contentList = response.contents.list;
                this.lastPage = response.contents.totalPages;
            }
        }
        async updateSortOrder(order) {
            if (order === this.order) {
                this.desc = !this.desc;
            }
            else {
                this.order = order;
                this.desc = true;
            }
            await this.updatePagination(1);
        }
        async updateDataType(datatype) {
            this.datatype = datatype;
            await this.updatePagination(1);
        }
        getSortOrderArrow(order) {
            if (order !== this.order) {
                return html `&#9660;`;
            }
            if (this.desc === true) {
                return html `&#9660;`;
            }
            return html `&#9650;`;
        }
        async updatePagination(currentPage) {
            this.currentPage = currentPage;
            showLoadingOverlay();
            await this.updateContentList();
            hideLoadingOverlay();
        }
        async firstUpdated() {
            await this.updatePagination(1);
        }
    }
    __decorate([
        property({ type: Array })
    ], GlyCombPaginationMixinClass.prototype, "contentList", void 0);
    __decorate([
        property({ type: Number })
    ], GlyCombPaginationMixinClass.prototype, "currentPage", void 0);
    __decorate([
        property({ type: Number })
    ], GlyCombPaginationMixinClass.prototype, "lastPage", void 0);
    return GlyCombPaginationMixinClass;
};
