var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
const style = await import('@/js/style');
let GlyCombFooter = class GlyCombFooter extends LitElement {
    render() {
        return html `
      <footer class="footer">
        <div class="container">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <a
                  class="heading is-size-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://doc.glycosmos.org/en/glycomb/help"
                  >Help</a
                >
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <a
                  class="heading is-size-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://glycosmos.org/license"
                  >Terms and Conditions</a
                >
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <a
                  class="heading is-size-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://glycosmos.org/policies"
                  >Site Policy</a
                >
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <a
                  class="heading is-size-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@glycosmos.org"
                  >Contact Us</a
                >
              </div>
            </div>
          </nav>

          <div class="content has-text-centered">&copy; 2023 GlyComb</div>
        </div>
      </footer>
    `;
    }
};
GlyCombFooter.styles = style.cssResult;
GlyCombFooter = __decorate([
    customElement('glycomb-footer')
], GlyCombFooter);
export { GlyCombFooter };
