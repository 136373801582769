var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { PageElement } from '@/js/pages/base/page-element';
import { getAccessionsListApiPath } from '@/js/utilities/url';
import { showDate } from '@/js/utilities/view_helper';
import { GlyCombPaginationMixin } from '@/js/pages/mixins/glycomb-pagination-mixin';
const GlyCombPaginationBase = GlyCombPaginationMixin(PageElement);
const style = await import('@/js/style');
let GlyCombGlycopeptideAccession = class GlyCombGlycopeptideAccession extends GlyCombPaginationBase {
    constructor() {
        super(...arguments);
        this.apiPath = getAccessionsListApiPath();
        this.datatype = 'Glycopeptide';
        this.order = 'accession_date';
        this.authorizationRequired = false;
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <div class="block">
            <h1 class="title">Glycopeptide List</h1>

            <div class="table-container">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th
                      class="glycomb-pagination-sorting-header"
                      @click=${(_) => this.updateSortOrder('accession')}
                    >
                      Accession Number ${this.getSortOrderArrow('accession')}
                    </th>
                    <th
                      class="glycomb-pagination-sorting-header"
                      @click=${(_) => this.updateSortOrder('accession_date')}
                    >
                      Registration Date
                      ${this.getSortOrderArrow('accession_date')}
                    </th>
                    <th
                      class="glycomb-pagination-sorting-header"
                      @click=${(_) => this.updateSortOrder('publication_date')}
                    >
                      Publication Date
                      ${this.getSortOrderArrow('publication_date')}
                    </th>
                    <th
                      class="glycomb-pagination-sorting-header"
                      @click=${(_) => this.updateSortOrder('rdfize_date')}
                    >
                      RDFize Date ${this.getSortOrderArrow('rdfize_date')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  ${this.contentList.map(accession => html `
                        <tr>
                          <td>
                            <a
                              href="${`/glycopeptide-entry?accession_number=${accession.accession}`}"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              ${accession.accession}
                            </a>
                          </td>
                          <td>${showDate(accession.accessionDate)}</td>
                          <td>${showDate(accession.publicationDate)}</td>
                          <td>${showDate(accession.rdfizeDate)}</td>
                        </tr>
                      `)}
                </tbody>
              </table>
            </div>

            <glycomb-pagination
              currentPage=${this.currentPage}
              lastPage=${this.lastPage}
              .updatePagination=${(i) => this.updatePagination(i)}
            >
            </glycomb-pagination>
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombGlycopeptideAccession.styles = style.cssResult;
GlyCombGlycopeptideAccession = __decorate([
    customElement('glycomb-glycopeptide-accession')
], GlyCombGlycopeptideAccession);
export { GlyCombGlycopeptideAccession };
