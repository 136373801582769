var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { GlyCombInputTableMixin } from '@/js/widgets/input_table/glycomb-input-table-mixin';
import { showAminoAcidSequence, showGlycanStructure, showAdditionalData, showSite, showUserInput, } from '@/js/utilities/view_helper';
const GlyCombInputTableBase = GlyCombInputTableMixin(LitElement);
const style = await import('@/js/style');
let GlyCombInputTableGlycopeptide = class GlyCombInputTableGlycopeptide extends GlyCombInputTableBase {
    showLine(line) {
        return html `
      <tr>
        <td class="${GlyCombInputTableBase.getStatusBackgroundColor(line)}">
          ${GlyCombInputTableBase.showIcon(line)}
        </td>
        <td>${showUserInput(line.line)}</td>
        ${GlyCombInputTableBase.shouldShowLineInfo(line)
            ? html `
              <td>
                ${showAminoAcidSequence(this.paragraph.sequence, line.site)}
              </td>
              <td>${showSite(line.site)}</td>
              <td>
                ${showGlycanStructure(line.glycan, this.gtcIdAndImageMap)}
              </td>
              <td>${showAdditionalData(line.additionalData)}</td>
            `
            : html `<td colspan="4"></td>`}
      </tr>
    `;
    }
    render() {
        return html `
      <div class="block">
        <div class="divider">Sequence ${this.paragraphNumber}</div>
        <div class="glycomb-registration-table-container">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>Status</th>
                <th>User input</th>
                <th>Amino acid sequence</th>
                <th>Glycosylation site</th>
                <th>Glycan structure</th>
                <th>Additional data</th>
              </tr>
            </thead>
            <tbody>
              ${this.paragraph
            ? this.paragraph.lines.map(line => this.showLine(line))
            : html ``}
            </tbody>
          </table>
        </div>
      </div>
    `;
    }
};
GlyCombInputTableGlycopeptide.styles = style.cssResult;
GlyCombInputTableGlycopeptide = __decorate([
    customElement('glycomb-input-table-glycopeptide')
], GlyCombInputTableGlycopeptide);
export { GlyCombInputTableGlycopeptide };
