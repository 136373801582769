var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombPublishConfirmationOverlay_1;
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
const style = await import('@/js/style');
let GlyCombPublishConfirmationOverlay = GlyCombPublishConfirmationOverlay_1 = class GlyCombPublishConfirmationOverlay extends LitElement {
    constructor() {
        super(...arguments);
        this.onProceed = () => {
            console.error('glycomb-publish-confirmation-overlay - callback function is not updated.');
        };
    }
    static showOverlay(onProceed) {
        const overlay = new GlyCombPublishConfirmationOverlay_1();
        overlay.onProceed = onProceed;
        document.body.appendChild(overlay);
        return overlay;
    }
    static hideOverlay(overlay) {
        document.body.removeChild(overlay);
    }
    proceedBtnClickHandler(_) {
        GlyCombPublishConfirmationOverlay_1.hideOverlay(this);
        this.onProceed();
    }
    cancelBtnClickHandler(_) {
        GlyCombPublishConfirmationOverlay_1.hideOverlay(this);
    }
    render() {
        return html `
      <div class="modal is-active glycomb-block-modal">
        <button
          class="modal-background glycomb-modal-background"
          @click="${this.cancelBtnClickHandler}"
        ></button>
        <div class="modal-card">
          <header class="modal-card-head">
            <nav class="level">
              <div class="level-left">
                <div class="level-item">
                  <glycomb-fa-icon
                    icon="fas fa-exclamation-triangle"
                    size="is-3"
                  ></glycomb-fa-icon>
                </div>
                <div class="level-item has-text-centered">
                  <p class="modal-card-title">Confirm</p>
                </div>
              </div>
              <div class="level-right is-hidden-mobile">
                <div class="level-item">
                  <button
                    class="delete"
                    aria-label="close"
                    @click="${this.cancelBtnClickHandler}"
                  ></button>
                </div>
              </div>
            </nav>
          </header>
          <section class="modal-card-body">
            Do you really open this submission entry to the public?
            <br />
            <strong>This action is irreversible.</strong>
          </section>
          <footer class="modal-card-foot">
            <div class="buttons is-centered">
              <button
                class="button is-success"
                @click="${this.proceedBtnClickHandler}"
              >
                <span class="icon">
                  <glycomb-fa-icon
                    icon="fas fa-lock-open"
                    size="is-5"
                  ></glycomb-fa-icon>
                </span>
                <span>I understand and wish to proceed</span>
              </button>

              <button
                class="button is-danger"
                @click="${this.cancelBtnClickHandler}"
              >
                Cancel
              </button>
            </div>
          </footer>
        </div>
      </div>
    `;
    }
};
GlyCombPublishConfirmationOverlay.styles = style.cssResult;
GlyCombPublishConfirmationOverlay = GlyCombPublishConfirmationOverlay_1 = __decorate([
    customElement('glycomb-publish-confirmation-overlay')
], GlyCombPublishConfirmationOverlay);
export { GlyCombPublishConfirmationOverlay };
