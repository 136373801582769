var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombMultiplePublishConfirmationOverlay_1;
import { LitElement, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import * as async from 'async';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { getBaseUrl, getGoogleSigninPath, publishAccessionApiPath, } from '@/js/utilities/url';
import { getApiKey } from '@/js/utilities/cookie';
import { redirectTo } from '@/js/utilities/auth';
const style = await import('@/js/style');
let GlyCombMultiplePublishConfirmationOverlay = GlyCombMultiplePublishConfirmationOverlay_1 = class GlyCombMultiplePublishConfirmationOverlay extends LitElement {
    constructor() {
        super(...arguments);
        this.onProceed = () => {
            console.error('glycomb-multiple-publish-confirmation-overlay - callback function is not updated.');
        };
    }
    static showOverlay(onProceed) {
        const overlay = new GlyCombMultiplePublishConfirmationOverlay_1();
        overlay.onProceed = onProceed;
        document.body.appendChild(overlay);
        return overlay;
    }
    static hideOverlay(overlay) {
        document.body.removeChild(overlay);
    }
    async proceedBtnClickHandler(_) {
        showLoadingOverlay();
        const submissionNumbers = this.submissionNumbersTextAreaElement.value;
        const submissionNumberList = submissionNumbers
            .trim()
            .split(/\r?\n/)
            .map(s => s.trim())
            .filter(s => s.length > 0);
        const apiKey = getApiKey();
        if (Option.isSome(apiKey)) {
            await async.eachSeries(submissionNumberList, async (submissionNumber) => {
                await fetchFromGlyCombApi({
                    url: `${getBaseUrl()}/${publishAccessionApiPath()}/${submissionNumber}`,
                    method: 'GET',
                    contentType: 'application/json',
                    authorizationRequired: true,
                });
            });
        }
        else {
            redirectTo(getGoogleSigninPath());
        }
        hideLoadingOverlay();
        GlyCombMultiplePublishConfirmationOverlay_1.hideOverlay(this);
        this.onProceed();
    }
    cancelBtnClickHandler(_) {
        GlyCombMultiplePublishConfirmationOverlay_1.hideOverlay(this);
    }
    render() {
        return html `
      <div class="modal is-active glycomb-block-modal">
        <button
          class="modal-background glycomb-modal-background"
          @click="${this.cancelBtnClickHandler}"
        ></button>
        <div class="modal-card">
          <header class="modal-card-head">
            <nav class="level">
              <div class="level-left">
                <div class="level-item">
                  <glycomb-fa-icon
                    icon="fas fa-exclamation-triangle"
                    size="is-3"
                  ></glycomb-fa-icon>
                </div>
                <div class="level-item has-text-centered">
                  <p class="modal-card-title">Confirm</p>
                </div>
              </div>
              <div class="level-right is-hidden-mobile">
                <div class="level-item">
                  <button
                    class="delete"
                    aria-label="close"
                    @click="${this.cancelBtnClickHandler}"
                  ></button>
                </div>
              </div>
            </nav>
          </header>
          <section class="modal-card-body">
            <p>
              Please paste the submission numbers you want to make public below
              line by line. All of the specified submissions will be opened by
              clicking the "I understand and wish to proceed" button.
              <strong>This action is irreversible.</strong>
            </p>
            <textarea
              id="submissionNumbersTextArea"
              class="textarea"
              rows="15"
              placeholder="Please paste the submission numbers line by line here..."
            ></textarea>
          </section>
          <footer class="modal-card-foot">
            <div class="buttons is-centered">
              <button
                class="button is-success"
                @click="${this.proceedBtnClickHandler}"
              >
                <span class="icon">
                  <glycomb-fa-icon
                    icon="fas fa-lock-open"
                    size="is-5"
                  ></glycomb-fa-icon>
                </span>
                <span>I understand and wish to proceed</span>
              </button>

              <button
                class="button is-danger"
                @click="${this.cancelBtnClickHandler}"
              >
                Cancel
              </button>
            </div>
          </footer>
        </div>
      </div>
    `;
    }
};
GlyCombMultiplePublishConfirmationOverlay.styles = style.cssResult;
__decorate([
    query('#submissionNumbersTextArea')
], GlyCombMultiplePublishConfirmationOverlay.prototype, "submissionNumbersTextAreaElement", void 0);
GlyCombMultiplePublishConfirmationOverlay = GlyCombMultiplePublishConfirmationOverlay_1 = __decorate([
    customElement('glycomb-multiple-publish-confirmation-overlay')
], GlyCombMultiplePublishConfirmationOverlay);
export { GlyCombMultiplePublishConfirmationOverlay };
