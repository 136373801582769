var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { GlyCombPublishConfirmationOverlay } from '@/js/widgets/glycomb-publish-confirmation-overlay';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { getUrlQueryParameter, getBaseUrl, getGoogleSigninPath, getSubmissionDataApiPath, publishAccessionApiPath, } from '@/js/utilities/url';
import { getApiKey } from '@/js/utilities/cookie';
import { redirectTo } from '@/js/utilities/auth';
import { isGtcId } from '@/js/utilities/gtc_glycan_image';
export const GlyCombSubmissionMixin = (superClass) => {
    class GlyCombSubmissionMixinClass extends superClass {
        constructor() {
            super(...arguments);
            // Required by IHasGtcGlycanImageElement interface
            this.gtcIdAndImageMap = {};
            this.submissionOpt = Option.none;
            this.submissionList = [];
            this.receptionNumberOpt = getUrlQueryParameter('reception_number');
        }
        async updateSubmission() {
            if (Option.isNone(this.receptionNumberOpt)) {
                return;
            }
            const apiKey = getApiKey();
            if (Option.isSome(apiKey)) {
                const receptionNumber = this.receptionNumberOpt.value;
                const responseOpt = await fetchFromGlyCombApi({
                    url: `${getBaseUrl()}${getSubmissionDataApiPath()}/${receptionNumber}`,
                    method: 'GET',
                    contentType: 'application/json',
                    authorizationRequired: true,
                });
                this.submissionOpt = Option.map(response => response.contents)(responseOpt);
                if (Option.isSome(this.submissionOpt)) {
                    // Extract data
                    const { reception } = this.submissionOpt.value;
                    this.submissionList = reception
                        .split(/\n/)
                        .map(line => line.split(/\t/));
                    // Collect GlyTouCan IDs and show the images
                    const gtcIds = this.submissionList
                        .map(line => line[2])
                        .filter(glycan => isGtcId(glycan));
                    this.updateGtcIdAndImageMap(gtcIds);
                }
            }
            else {
                redirectTo(getGoogleSigninPath());
            }
        }
        async firstUpdated() {
            showLoadingOverlay();
            await this.updateSubmission();
            hideLoadingOverlay();
        }
        async publishButtonClickHandler(e) {
            e.preventDefault();
            GlyCombPublishConfirmationOverlay.showOverlay(async () => {
                showLoadingOverlay();
                const apiKey = getApiKey();
                if (Option.isSome(apiKey)) {
                    const receptionNumberOpt = getUrlQueryParameter('reception_number');
                    if (Option.isSome(receptionNumberOpt)) {
                        await fetchFromGlyCombApi({
                            url: `${getBaseUrl()}/${publishAccessionApiPath()}/${receptionNumberOpt.value}`,
                            method: 'GET',
                            contentType: 'application/json',
                            authorizationRequired: true,
                        });
                    }
                }
                else {
                    redirectTo(getGoogleSigninPath());
                }
                await this.updateSubmission();
                hideLoadingOverlay();
            });
        }
    }
    __decorate([
        property({ type: Object })
    ], GlyCombSubmissionMixinClass.prototype, "gtcIdAndImageMap", void 0);
    __decorate([
        property({ type: Object })
    ], GlyCombSubmissionMixinClass.prototype, "submissionOpt", void 0);
    __decorate([
        property({ type: Array })
    ], GlyCombSubmissionMixinClass.prototype, "submissionList", void 0);
    return GlyCombSubmissionMixinClass;
};
