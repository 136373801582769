var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { GlyCombRegistrationUploadMixin } from '@/js/pages/mixins/glycomb-registration-upload-mixin';
import sampleData from '@/assets/samples/sample-glycoproteins.txt';
const GlyCombRegistrationUploadBase = GlyCombRegistrationUploadMixin(LitElement);
const style = await import('@/js/style');
let GlyCombRegistrationUploadGlycoproteins = class GlyCombRegistrationUploadGlycoproteins extends GlyCombRegistrationUploadBase {
    constructor() {
        super(...arguments);
        this.placeholderString = sampleData.trim();
        this.datatype = 'Glycoprotein';
        this.color = 'info';
        this.theme = `is-${this.color}`;
        this.backgroundColor = `has-background-${this.color}-light`;
        this.backgroundColorActive = `has-background-${this.color}`;
    }
    firstUpdated() {
        super.firstUpdated();
        this.addEventListener('glycomb-glycoprotein-file-upload-config-changed', _ => this.checkSubmitReady());
    }
    render() {
        return html `
      <div class="columns">
        <div class="column is-12-mobile is-4-tablet is-3-desktop">
          <nav class="menu ${this.backgroundColor}">
            <p class="menu-label">Select input method</p>
          </nav>
          <ul class="menu-list">
            <li>
              <a
                id="input-method-menu-clipboard"
                class="menu-contains-fa-icon
                        ${this.selectedInputMethod === 'clipboard'
            ? `is-active ${this.backgroundColorActive}`
            : this.backgroundColor}"
                href="#"
              >
                <span class="icon">
                  <glycomb-fa-icon
                    icon="fas fa-clipboard"
                    size="is-7"
                  ></glycomb-fa-icon>
                </span>
                &nbsp;Input from clipboard
              </a>
            </li>
            <li>
              <a
                id="input-method-menu-file"
                class="menu-contains-fa-icon
                        ${this.selectedInputMethod === 'file'
            ? `is-active ${this.backgroundColorActive}`
            : this.backgroundColor}"
                href="#"
              >
                <span class="icon">
                  <glycomb-fa-icon
                    icon="far fa-file-alt"
                    size="is-7"
                  ></glycomb-fa-icon>
                </span>
                &nbsp;Input from file
              </a>
            </li>
          </ul>
        </div>

        <div class="column">
          <glycomb-collapsible-message
            messageClass=${this.theme}
            messageHeaderClass="glycomb-registration-example-data-header"
            headerTitle="Example Glycoprotein Data"
          >
            <div class="glycomb-float-button-wrapper">
              <pre class="glycomb-registration-code-area"><code>${this
            .placeholderString}</code></pre>
              <button
                class="button glycomb-float-button ${this.theme}"
                @click=${this.copyToClipboardButtonClickHandler}
              >
                <span class="icon"
                  ><glycomb-fa-icon
                    icon="fas fa-copy"
                    size="is-6"
                  ></glycomb-fa-icon></span
                ><span>Copy</span>
              </button>
            </div>
          </glycomb-collapsible-message>

          <form>
            <div class="field" .hidden=${this.selectedInputMethod === 'file'}>
              <label class="label">
                Paste your data to the text area below:
              </label>
              <textarea
                class="textarea"
                rows="15"
                placeholder="Paste your data here..."
                @input="${this.textAreaInputHandler}"
                @keydown="${this.textAreaKeydownHandler}"
              ></textarea>
            </div>

            <div
              class="field"
              .hidden=${this.selectedInputMethod === 'clipboard'}
            >
              <label class="label">
                Choose your file to submit (TSV/XLSX/XLS file format):
              </label>
              <div class="control">
                <div class="file has-name is-fullwidth">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file"
                      accept=".tsv,.xlsx,.xls,.txt"
                      @change="${this.fileChangeHandler}"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <glycomb-fa-icon
                          icon="fas fa-file-upload"
                          size="is-7"
                        ></glycomb-fa-icon>
                      </span>
                      <span class="file-label">Select a file...</span>
                    </span>
                    <span class="file-name">${this.inputFileName}</span>
                  </label>
                </div>
              </div>
            </div>

            <div
              .hidden=${this.selectedInputMethod === 'clipboard' ||
            (this.fileInputElement &&
                this.fileInputElement.files.length === 0)}
            >
              <glycomb-glycoprotein-file-upload-config
                id="glycomb-file-upload-config"
                .inputFileContent=${this.inputFileContent}
              ></glycomb-glycoprotein-file-upload-config>
            </div>

            <div class="field">
              <div class="buttons is-centered">
                <button
                  class="button is-medium is-success"
                  .disabled=${!this.isSubmitReady}
                  @click="${this.submitButtonClickHandler}"
                >
                  <span class="icon">
                    <glycomb-fa-icon
                      id="submitButtonIcon"
                      icon="fas fa-cloud-upload-alt"
                      size="is-4"
                    ></glycomb-fa-icon>
                  </span>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    `;
    }
};
GlyCombRegistrationUploadGlycoproteins.styles = style.cssResult;
GlyCombRegistrationUploadGlycoproteins = __decorate([
    customElement('glycomb-registration-upload-glycoproteins')
], GlyCombRegistrationUploadGlycoproteins);
export { GlyCombRegistrationUploadGlycoproteins };
