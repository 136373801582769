var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { property, query, customElement } from 'lit/decorators.js';
import BulmaCollapsible from '@creativebulma/bulma-collapsible';
const style = await import('@/js/style');
let GlyCombCollapsibleMessage = class GlyCombCollapsibleMessage extends LitElement {
    constructor() {
        super(...arguments);
        this.bulmaCollapsible = null;
        this.isCollapsed = true;
        this.messageClass = '';
        this.messageHeaderClass = '';
        this.headerTitle = 'Header Title';
    }
    firstUpdated() {
        this.bulmaCollapsible = new BulmaCollapsible(this.containerElement);
    }
    emitEvent() {
        const event = new CustomEvent(this.isCollapsed ? 'collapse' : 'expand', {
            detail: {},
        });
        this.dispatchEvent(event);
    }
    clickHandler(_) {
        const isCollapsed = this.bulmaCollapsible.collapsed();
        if (isCollapsed) {
            this.bulmaCollapsible.expand();
        }
        else {
            this.bulmaCollapsible.collapse();
        }
        this.isCollapsed = !this.isCollapsed;
        this.emitEvent();
    }
    escapeKeyUpHandler(e) {
        if (e.key === 'Escape' || e.key === 'Esc') {
            this.bulmaCollapsible.collapse();
            this.isCollapsed = true;
            this.emitEvent();
        }
    }
    render() {
        return html `
      <article class="message ${this.messageClass}">
        <div
          class="message-header ${this.messageHeaderClass}"
          @click=${this.clickHandler}
          @keyup=${this.escapeKeyUpHandler}
        >
          <p>${this.headerTitle}</p>
          <span class="icon">
            ${this.isCollapsed
            ? html `<glycomb-fa-icon
                  icon="fas fa-angle-down"
                  size="is-7"
                ></glycomb-fa-icon>`
            : html `<glycomb-fa-icon
                  icon="fas fa-angle-up"
                  size="is-7"
                ></glycomb-fa-icon>`}
          </span>
        </div>
        <div id="container" class="message-body is-collapsible">
          <div class="message-body-content">
            <slot></slot>
          </div>
        </div>
      </article>
    `;
    }
};
GlyCombCollapsibleMessage.styles = style.cssResult;
__decorate([
    query('#container')
], GlyCombCollapsibleMessage.prototype, "containerElement", void 0);
__decorate([
    property({ type: Boolean })
], GlyCombCollapsibleMessage.prototype, "isCollapsed", void 0);
__decorate([
    property({ type: String })
], GlyCombCollapsibleMessage.prototype, "messageClass", void 0);
__decorate([
    property({ type: String })
], GlyCombCollapsibleMessage.prototype, "messageHeaderClass", void 0);
__decorate([
    property({ type: String })
], GlyCombCollapsibleMessage.prototype, "headerTitle", void 0);
GlyCombCollapsibleMessage = __decorate([
    customElement('glycomb-collapsible-message')
], GlyCombCollapsibleMessage);
export { GlyCombCollapsibleMessage };
