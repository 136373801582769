var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import * as Option from 'fp-ts/Option';
import { PageElement } from '@/js/pages/base/page-element';
import { GlyCombEntryMixin } from '@/js/pages/mixins/glycomb-entry-mixin';
import { updateGtcIdAndImageMap } from '@/js/utilities/gtc_glycan_image';
import { showUserInput, showDate, showGlycanStructure, showLog, showSite, showUniProtId, showLongAminoAcidSequence, } from '@/js/utilities/view_helper';
import { GlyCombProteinSequenceMixin } from '@/js/widgets/protein_sequence/glycomb-protein-sequence-mixin';
const GlyCombProteinSequenceBase = GlyCombProteinSequenceMixin(PageElement);
const GlyCombEntryBase = GlyCombEntryMixin(GlyCombProteinSequenceBase);
const style = await import('@/js/style');
let GlyCombGlycoproteinEntry = class GlyCombGlycoproteinEntry extends GlyCombEntryBase {
    updateGtcIdAndImageMap(gtcIds) {
        updateGtcIdAndImageMap(gtcIds, this);
    }
    showLine(line, rowNum, totalLineCount) {
        if (line.length >= 3) {
            const site = line[1];
            const glycan = line[2];
            if (rowNum === 0) {
                const sequence = Option.isSome(this.sequenceOpt)
                    ? this.sequenceOpt.value.sequence
                    : '';
                const siteList = this.entryList.map(l => l[1]);
                return html `
          <tr>
            <td rowspan="${totalLineCount}">
              ${sequence !== ''
                    ? html `
                    <pre
                      class="glycomb-registration-code-area"
                    ><code>${showLongAminoAcidSequence(sequence, siteList)}</code></pre>
                  `
                    : html ` <span>Failed to fetch the protein sequence</span> `}
            </td>
            <td>${showSite(site)}</td>
            <td>${showGlycanStructure(glycan, this.gtcIdAndImageMap)}</td>
          </tr>
        `;
            }
            return html `
        <tr>
          <td>${showSite(site)}</td>
          <td>${showGlycanStructure(glycan, this.gtcIdAndImageMap)}</td>
        </tr>
      `;
        }
        return html `
      <tr>
        <td colspan="3">Invalid entry "${showUserInput(line.join('\t'))}"</td>
      </tr>
    `;
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <div class="block">
            <h2 class="title is-4">Glycoprotein Entry</h1>

            ${Option.isSome(this.accessionNumberOpt)
            ? html `
                    <h1 class="title is-3">
                      ${Option.getOrElse(() => '')(this.accessionNumberOpt)}
                    </h1>
                  `
            : html `<p>Accession number was not specified.</p>`}

            ${Option.isSome(this.entryOpt)
            ? html `
                  <div class="table-container">
                    <table class="table is-striped is-fullwidth">
                      <thead>
                        <tr>
                          <th>Protein sequence</th>
                          <th>Glycosylation site</th>
                          <th>Glycan structure</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${this.entryList.map((line, i) => this.showLine(line, i, this.entryList.length))}
                      </tbody>
                    </table>
                  </div>
                  <fieldset>
                    <div class="field">
                      <label class="label is-medium">Accession Number</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value="${this.entryOpt.value.accession
                ? this.entryOpt.value.accession
                : 'The accession number does not exist or still be pending...'}">
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-key" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-medium">Registration Date</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value="${showDate(this.entryOpt.value.accessionDate)}">
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-medium">Registration Log</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value="${showLog(this.entryOpt.value.accessionLog)}">
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-medium">Publication Date</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value="${showDate(this.entryOpt.value.publicationDate)}">
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-clock" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-medium">Publication Log</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value="${showLog(this.entryOpt.value.publicationLog)}">
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-medium">Associated Metadata</label>
                      <div class="control has-icons-left">
                        <input class="input is-medium"
                               type="text"
                               readonly=true
                               .value=${this.entryOpt.value.metadata
                .map(md => showUniProtId(md))
                .join(', ')}>
                        </input>
                        <span class="icon is-medium is-left">
                          <glycomb-fa-icon icon="fas fa-comment-dots" size="is-5"></glycomb-fa-icon>
                        </span>
                      </div>
                    </div>
                  </fieldset>`
            : Option.isSome(this.accessionNumberOpt)
                ? html `<p>Data not found. It may not be published yet.</p>`
                : html ``}
          </div>
        </div>
      </section>
    `;
    }
};
GlyCombGlycoproteinEntry.styles = style.cssResult;
GlyCombGlycoproteinEntry = __decorate([
    customElement('glycomb-glycoprotein-entry')
], GlyCombGlycoproteinEntry);
export { GlyCombGlycoproteinEntry };
