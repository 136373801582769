var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageElement } from '@/js/pages/base/page-element';
const style = await import('@/js/style');
// TODO: Login checking is required.
let GlyCombRegistration = class GlyCombRegistration extends PageElement {
    constructor() {
        super(...arguments);
        this.initialInputValidationContent = [];
        this.datatype = 'Glycopeptide';
        this.registrationStep = 0;
        this.inputValidationContent = this.initialInputValidationContent;
        this.registrationRequestResponses = [];
    }
    registrationStateChangedHandler(e) {
        // Scroll to top of the page on the state change.
        window.scrollTo(0, 0);
        this.registrationStep = e.detail.nextStep;
        // Initialize inputValidationContent when returns to first step
        // to prevent error reporting.
        if (e.detail.nextStep === 0) {
            this.inputValidationContent = this.initialInputValidationContent;
        }
        if (e.detail.inputValidationContent) {
            this.datatype = e.detail.datatype;
            this.inputValidationContent = e.detail.inputValidationContent;
        }
        if (e.detail.registrationRequestResponses) {
            this.registrationRequestResponses = e.detail.registrationRequestResponses;
        }
    }
    render() {
        return html `
      <section class="section">
        <div class="container">
          <h1 class="title">Data Registration Request Form</h1>

          <ul class="steps has-content-centered is-balanced is-medium has-gaps">
            <li
              class="steps-segment
                       ${this.registrationStep === 0 ? 'is-active' : ''}"
            >
              <span
                class="steps-marker ${this.registrationStep > 0
            ? 'is-hollow'
            : ''}"
                >1</span
              >
              <div class="steps-content">
                <p class="is-size-4">Upload</p>
              </div>
            </li>
            <li
              class="steps-segment
                       ${this.registrationStep === 1 ? 'is-active' : ''}"
            >
              <span
                class="steps-marker ${this.registrationStep > 1
            ? 'is-hollow'
            : ''}"
                >2</span
              >
              <div class="steps-content">
                <p class="is-size-4">Confirmation</p>
              </div>
            </li>
            <li
              class="steps-segment
                       ${this.registrationStep === 2 ? 'is-active' : ''}"
            >
              <span class="steps-marker">3</span>
              <div class="steps-content">
                <p class="is-size-4">Completion</p>
              </div>
            </li>
          </ul>

          <glycomb-registration-upload
            .hidden=${this.registrationStep !== 0}
            @registration-state-changed=${this.registrationStateChangedHandler}
          >
          </glycomb-registration-upload>

          <glycomb-registration-confirmation
            .hidden=${this.registrationStep !== 1}
            .inputValidationContent="${this.inputValidationContent}"
            .datatype=${this.datatype}
            @registration-state-changed=${this.registrationStateChangedHandler}
          >
          </glycomb-registration-confirmation>

          <glycomb-registration-completed
            .hidden=${this.registrationStep !== 2}
            .registrationRequestResponses=${this.registrationRequestResponses}
          >
          </glycomb-registration-completed>
        </div>
      </section>
    `;
    }
};
GlyCombRegistration.styles = style.cssResult;
__decorate([
    property({ type: Number })
], GlyCombRegistration.prototype, "registrationStep", void 0);
__decorate([
    property({ type: Array })
], GlyCombRegistration.prototype, "inputValidationContent", void 0);
__decorate([
    property({ type: Array })
], GlyCombRegistration.prototype, "registrationRequestResponses", void 0);
GlyCombRegistration = __decorate([
    customElement('glycomb-registration')
], GlyCombRegistration);
export { GlyCombRegistration };
