/**
 * GlyComb configuration file
 */
export default {
    expire_time_check_interval: 15000,
    expire_time_check_threshold: 60000,
    toast_notification_timeout: 10000,
    yaml_location: 'https://gitlab.glyco.info/glycosmos/glycombgroup/document/-/blob/master/forUiDocument/error_messages.yaml',
    cookie: {
        cookie_policy_key: 'cookie_policy',
        cookie_policy_value: 'accepted',
        login_check_key: 'loggedin',
        login_check_value: '',
        error_code_key: 'errcode',
        jwt_api_key: 'token',
    },
    url: {
        server: {
            prod: 'glycosmos.org',
            beta: 'beta.glycosmos.org',
            alpha: 'alpha.glycosmos.org',
            test: 'test.glycosmos.org',
        },
        google_signin_url: {
            prod: 'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=963262858981-85dara1nfj72e5qgo165901eq13bjukb.apps.googleusercontent.com&access_type=offline&redirect_uri=https%3A%2F%2Fglycomb.glycosmos.org%2Fauth%2Fgoogle%2F&scope=openid%20email&flowName=GeneralOAuthFlow',
            beta: 'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=282484793286-ladpsvof52b2itrcagnvouqp7qjtqs8i.apps.googleusercontent.com&access_type=offline&redirect_uri=https%3A%2F%2Fglycomb.beta.glycosmos.org%2Fauth%2Fgoogle%2F&scope=openid%20email&flowName=GeneralOAuthFlow',
            alpha: 'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=188226877423-934l638ls6cis5ouktjg8ailg46t2vdi.apps.googleusercontent.com&access_type=offline&redirect_uri=https%3A%2F%2Fglycomb.alpha.glycosmos.org%2Fauth%2Fgoogle%2F&scope=openid%20email&flowName=GeneralOAuthFlow',
            test: 'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=188226877423-934l638ls6cis5ouktjg8ailg46t2vdi.apps.googleusercontent.com&access_type=offline&redirect_uri=https%3A%2F%2Fglycomb.test.glycosmos.org%2Fauth%2Fgoogle%2F&scope=openid%20email&flowName=GeneralOAuthFlow',
        },
        accession_count_api_url: '/api/accessions-count',
        input_validation_api_url: '/api/input-validation',
        registration_request_api_url: '/auth/api/registration-request',
        search_text_api_url: '/api/search-text',
        submissions_list_api_url: '/auth/api/submissions-list',
        submission_data_api_url: '/auth/api/get-entry',
        entry_data_api_url: '/api/get-entry',
        sequence_data_api_url: '/api/get-sequence',
        accessions_list_api_url: '/api/accessions-list',
        publish_accession_api_url: '/auth/api/publish-entry',
        user_profile_api_url: '/auth/api/user-profile',
    },
    gtc_glycan_image: {
        glycan_image_load_interval: 200,
        snfg_glycan_image_url: (gtcId) => `https://image.glycosmos.org/snfg/png/${gtcId}`,
        wurcs2image_glycan_image_url: (gtcId) => `https://api.glycosmos.org/wurcs2image/0.10.0/png/json/${gtcId}`,
    },
    pages: {
        registration: {
            state_change_event_name: 'registration-state-changed',
        },
        byonic_converter: {
            state_change_event_name: 'byonic-converter-state-changed',
        },
    },
};
