import * as jwtDecode from 'jwt-decode';
import * as Option from 'fp-ts/Option';
import { readCookieEntry } from '@/js/utilities/cookie';
import { GlyCombLoginTimeoutOverlay } from '@/js/widgets/glycomb-login-timeout-overlay';
import config from '@/js/config';
function getRemainingTime(expirationTime) {
    const expDate = expirationTime * 1000; // seconds to milliseconds
    // Current timestamp in milliseconds
    const nowDate = Date.now();
    // Return remaining time in milliseconds
    return expDate - nowDate;
}
export function isLoggedIn() {
    const apiKeyOpt = readCookieEntry(config.cookie.jwt_api_key);
    if (Option.isSome(apiKeyOpt)) {
        try {
            const decodedJwt = jwtDecode.default(apiKeyOpt.value);
            const remainingTime = getRemainingTime(decodedJwt.exp);
            return remainingTime > 0;
        }
        catch (e) {
            return false;
        }
    }
    return false;
}
export function watchTokenExpirationDate(intervalMs, thresholdMs) {
    const watcher = setInterval(() => {
        if (isLoggedIn()) {
            const apiKeyOpt = readCookieEntry(config.cookie.jwt_api_key);
            const decodedJwt = jwtDecode.default(Option.getOrElse(() => '')(apiKeyOpt));
            const remainingTime = getRemainingTime(decodedJwt.exp);
            if (remainingTime < thresholdMs) {
                clearInterval(watcher);
                GlyCombLoginTimeoutOverlay.showOverlay();
            }
        }
    }, intervalMs);
}
export function redirectTo(path) {
    window.location.href = path;
}
