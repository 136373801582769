var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var GlyCombRegistrationConfirmation_1;
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as async from 'async';
import * as Option from 'fp-ts/Option';
import * as Array from 'fp-ts/Array';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getBaseUrl, getRegistrationRequestApiPath, getGoogleSigninPath, } from '@/js/utilities/url';
import { handleGlyCombError } from '@/js/utilities/error_handling';
import { getApiKey } from '@/js/utilities/cookie';
import { updateGtcIdAndImageMap, isGtcId, } from '@/js/utilities/gtc_glycan_image';
import { isLoggedIn, redirectTo } from '@/js/utilities/auth';
import config from '@/js/config';
import messages from '@/js/utilities/error_messages';
import { fetchFromGlyCombApi } from '@/js/utilities/fetch';
import { GlyCombBrowserLocalError } from '@/js/types/error_handling';
const style = await import('@/js/style');
let GlyCombRegistrationConfirmation = GlyCombRegistrationConfirmation_1 = class GlyCombRegistrationConfirmation extends LitElement {
    constructor() {
        super(...arguments);
        this._inputValidationContent = [];
        this.datatype = 'Glycopeptide';
        // Required by IHasGtcGlycanImageElement interface
        this.gtcIdAndImageMap = {};
    }
    static async showMessagesForInvalidCodes(inputValidationContent) {
        const lines = inputValidationContent.flatMap(paragraph => paragraph.lines);
        const codes = lines
            .flatMap(line => [line.errors, line.warnings].flat())
            .map(code => code.code);
        const processedCodes = [];
        async.eachSeries(codes, async (code) => {
            if (!processedCodes.includes(code)) {
                await handleGlyCombError(async () => {
                    const target = messages.find(m => m.code === code);
                    if (!target) {
                        // Show an error message for the invalid error code.
                        throw new GlyCombBrowserLocalError('', code);
                    }
                });
            }
            processedCodes.push(code);
        });
    }
    get inputValidationContent() {
        return this._inputValidationContent;
    }
    set inputValidationContent(newVal) {
        const oldVal = this._inputValidationContent;
        this._inputValidationContent = newVal;
        GlyCombRegistrationConfirmation_1.showMessagesForInvalidCodes(newVal);
        // For GtcIds only
        this.refreshGlycanImages(oldVal, newVal);
    }
    refreshGlycanImages(oldVal, newVal) {
        // Crear the gtc id and image map.
        this.gtcIdAndImageMap = {};
        // Request redraw
        this.requestUpdate('inputValidationContent', oldVal);
        // Collect gtc ids
        const gtcIds = newVal.flatMap(paragraph => paragraph.lines
            .map(line => line.glycan)
            .filter(glycan => glycan) // Remove empty strings
            .filter(isGtcId) // Extract GlyTouCan Ids only
        );
        updateGtcIdAndImageMap(gtcIds, this);
    }
    containsNoError() {
        const errorParagraphCount = this.inputValidationContent
            .map(paragraph => paragraph.errorCount)
            .reduce((acc, n) => acc + n, 0);
        return errorParagraphCount === 0;
    }
    backButtonClickHandler(e) {
        // Back to upload step.
        e.preventDefault();
        const event = new CustomEvent(config.pages.registration.state_change_event_name, {
            bubbles: true,
            cancelable: false,
            composed: true,
            detail: {
                nextStep: 0,
            },
        });
        this.dispatchEvent(event);
    }
    async nextButtonClickHandler(e) {
        e.preventDefault();
        if (!isLoggedIn()) {
            redirectTo(getGoogleSigninPath());
            return;
        }
        showLoadingOverlay();
        await handleGlyCombError(async () => {
            const apiKeyOpt = getApiKey();
            if (Option.isSome(apiKeyOpt)) {
                const rawDataList = this.inputValidationContent.map(paragraph => paragraph.deduplicatedData);
                // 途中で (404、500、CORS、Network error などの) API との通信が
                // エラーとなる場合、残りの登録処理はすべてキャンセルしてエラー表示.
                const responseOpts = await async.mapSeries(rawDataList, async (rawData) => {
                    const params = new URLSearchParams();
                    params.append('datatype', this.datatype);
                    params.append('raw_data', rawData);
                    const responseOpt = await fetchFromGlyCombApi({
                        url: `${getBaseUrl()}${getRegistrationRequestApiPath()}`,
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        body: params,
                        authorizationRequired: true,
                    });
                    return responseOpt;
                });
                const responses = responseOpts
                    .filter(x => Option.isSome(x))
                    .map((x) => x.value);
                // 登録申請処理の API 呼び出しは複数回呼び出されうるが、それぞれの
                // 呼び出しの成否は互いに独立しているので、
                // responses の中には成功とエラーが混在する可能性がある.
                // エラーが存在する場合には次の結果画面でエラー表示する.
                // If all of the response is invalid, then the responses will be empty.
                // In such case, we have to stop the registration process.
                if (Array.isNonEmpty(responses)) {
                    const event = new CustomEvent(config.pages.registration.state_change_event_name, {
                        bubbles: true,
                        cancelable: false,
                        composed: true,
                        detail: {
                            nextStep: 2,
                            registrationRequestResponses: responses,
                        },
                    });
                    this.dispatchEvent(event);
                }
            }
            else {
                redirectTo(getGoogleSigninPath());
            }
        });
        hideLoadingOverlay();
    }
    render() {
        return html `
      ${this.containsNoError()
            ? html `
            <div class="notification is-success is-light">No error found.</div>
          `
            : html `
            <div class="notification is-danger is-light">
              Invalid entries were found in your input data. Please check your
              data again...
            </div>
          `}
      ${this.inputValidationContent.map((paragraph, idx) => this.datatype === 'Glycopeptide'
            ? html `
              <glycomb-input-table-glycopeptide
                paragraphNumber=${idx + 1}
                .paragraph=${paragraph}
                .gtcIdAndImageMap=${this.gtcIdAndImageMap}
              ></glycomb-input-table-glycopeptide>
            `
            : this.datatype === 'Glycoprotein'
                ? html `
              <glycomb-input-table-glycoprotein
                paragraphNumber=${idx + 1}
                .paragraph=${paragraph}
                .gtcIdAndImageMap=${this.gtcIdAndImageMap}
              ></glycomb-input-table-glycoprotein>
            `
                : html ``)}
      <div class="divider"></div>
      <form>
        <div class="field">
          <div class="buttons is-centered">
            ${this.containsNoError()
            ? html `
                  <button
                    class="button is-medium is-success"
                    @click=${this.nextButtonClickHandler}
                  >
                    <span class="icon">
                      <glycomb-fa-icon
                        icon="fas fa-cloud-upload-alt"
                        size="is-4"
                      ></glycomb-fa-icon>
                    </span>
                    <span>Submit</span>
                  </button>
                `
            : html ``}
            <button
              class="button is-medium is-danger"
              @click=${this.backButtonClickHandler}
            >
              Back
            </button>
          </div>
        </div>
      </form>
    `;
    }
};
GlyCombRegistrationConfirmation.styles = style.cssResult;
__decorate([
    property({ type: Array })
], GlyCombRegistrationConfirmation.prototype, "inputValidationContent", null);
__decorate([
    property({ type: String })
], GlyCombRegistrationConfirmation.prototype, "datatype", void 0);
__decorate([
    property({ type: Object })
], GlyCombRegistrationConfirmation.prototype, "gtcIdAndImageMap", void 0);
GlyCombRegistrationConfirmation = GlyCombRegistrationConfirmation_1 = __decorate([
    customElement('glycomb-registration-confirmation')
], GlyCombRegistrationConfirmation);
export { GlyCombRegistrationConfirmation };
