var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js';
import * as comlink from 'comlink';
import { range } from '@glycomb/glycomb-utils';
import { truncateLeading } from '@/js/utilities/miscellaneous';
import { getColumnDisplayName } from '@/js/utilities/view_helper';
import { hideLoadingOverlay, showLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import config from '@/js/config';
const style = await import('@/js/style');
const comberterUtilsWorker = comlink.wrap(new Worker(new URL('/js/workers/comberter_utils.worker.ts', import.meta.url)));
let GlyCombByonicConverterConfig = class GlyCombByonicConverterConfig extends LitElement {
    constructor() {
        super(...arguments);
        this.emptyFileName = '(No file selected)';
        this.maxFileNameLength = 100;
        this.peptideSequenceColumnRegex = /Peptide.*ProteinMetrics.*Confidential/;
        this.glycanStructureColumnRegex = /Glycans.*NHFAGNa/;
        this.modificationTypeColumnRegex = /Modification.*Type\(s\)/;
        this.fastaHeaderColumnRegex = /Protein Name/;
        this.peptideStartingPositionColumnRegex = /Starting.*position/;
        this.inputFileName = 'empty';
        this.displayFileName = this.emptyFileName;
        this.inputFileContent = {
            type: 'text',
            content: '',
        };
        this.selectedDatasetType = undefined;
        this.isFileSelected = false;
        this.isDatasetTypeSelected = false;
        this.maxRow = -1;
        this.maxColumn = -1;
        this.worksheetNames = [];
        this.worksheet = '';
        this.startRow = 0;
        this.endRow = 0;
        this.peptideSequenceColumn = -1;
        this.glycanStructureColumn = -1;
        this.modificationTypeColumn = -1;
        this.fastaHeaderColumn = -1;
        this.peptideStartingPositionColumn = -1;
        this.isConversionReady = false;
    }
    emitEvent(value) {
        this.dispatchEvent(new CustomEvent(config.pages.byonic_converter.state_change_event_name, {
            bubbles: true,
            composed: true,
            detail: {
                nextStep: 1,
                fileName: this.inputFileName,
                value,
            },
        }));
    }
    resetOptions() {
        this.isFileSelected = false;
        this.isDatasetTypeSelected = false;
        Array.from(this.datasetTypeInputElementList).forEach(radio => {
            radio.checked = false;
        });
        this.selectedDatasetType = undefined;
    }
    checkConversionReady() {
        if (this.selectedDatasetType === 'glycopeptides') {
            this.isConversionReady = [
                this.peptideSequenceColumn,
                this.glycanStructureColumn,
                this.modificationTypeColumn,
            ].every(c => c >= 0);
        }
        else if (this.selectedDatasetType === 'glycoproteins') {
            this.isConversionReady = [
                this.peptideSequenceColumn,
                this.glycanStructureColumn,
                this.modificationTypeColumn,
                this.fastaHeaderColumn,
                this.peptideStartingPositionColumn,
            ].every(c => c >= 0);
        }
        else {
            this.isConversionReady = false;
        }
    }
    setRowNumbers() {
        if (this.startRowInputElement) {
            this.startRowInputElement.value = (this.startRow + 1).toString();
        }
        if (this.endRowInputElement) {
            this.endRowInputElement.value = (this.endRow + 1).toString();
        }
    }
    async loadFileContent() {
        this.maxRow = -1;
        this.maxColumn = -1;
        this.worksheetNames = [];
        this.worksheet = '';
        this.startRow = 0;
        this.endRow = 0;
        this.peptideSequenceColumn = -1;
        this.glycanStructureColumn = -1;
        this.modificationTypeColumn = -1;
        this.fastaHeaderColumn = -1;
        this.peptideStartingPositionColumn = -1;
        if (this.inputFileContent.content !== '') {
            if (this.inputFileContent.type === 'text') {
                const [maxRow, maxColumn] = await Promise.all([
                    comberterUtilsWorker.getMaxRow(this.inputFileContent, ''),
                    comberterUtilsWorker.getMaxColumn(this.inputFileContent, ''),
                ]);
                this.maxRow = maxRow;
                this.maxColumn = maxColumn;
                this.endRow = maxRow - 1;
            }
            else if (this.inputFileContent.type === 'excel') {
                const worksheetNames = await comberterUtilsWorker.getWorksheetNames(this.inputFileContent.content);
                this.worksheetNames = worksheetNames;
                await this.changeWorksheet(worksheetNames[0]);
            }
        }
        this.setRowNumbers();
        this.checkConversionReady();
    }
    async changeWorksheet(worksheetName) {
        this.worksheet = worksheetName;
        this.startRow = 0;
        this.endRow = 0;
        this.peptideSequenceColumn = -1;
        this.glycanStructureColumn = -1;
        this.modificationTypeColumn = -1;
        this.fastaHeaderColumn = -1;
        this.peptideStartingPositionColumn = -1;
        const [maxRow, maxColumn] = await Promise.all([
            comberterUtilsWorker.getMaxRow(this.inputFileContent, this.worksheet),
            comberterUtilsWorker.getMaxColumn(this.inputFileContent, this.worksheet),
        ]);
        this.maxRow = maxRow;
        this.maxColumn = maxColumn;
        this.endRow = maxRow - 1;
        this.setRowNumbers();
        this.checkConversionReady();
    }
    async getHeaderLine(targetSheet) {
        const parsedData = await comberterUtilsWorker.parseFileContent(this.inputFileContent, targetSheet);
        const headerLine = parsedData[0];
        return headerLine;
    }
    async isStandardByonicResultFile() {
        const targetSheet = this.worksheetNames.find(worksheetName => worksheetName === 'Spectra');
        if (!targetSheet)
            return false;
        const [maxRow, maxColumn] = await Promise.all([
            comberterUtilsWorker.getMaxRow(this.inputFileContent, targetSheet),
            comberterUtilsWorker.getMaxColumn(this.inputFileContent, targetSheet),
        ]);
        if (maxRow < 2 || maxColumn < 23)
            return false;
        // Check the header content
        try {
            const headerLine = await this.getHeaderLine(targetSheet);
            const basicRegexList = [
                this.peptideSequenceColumnRegex,
                this.glycanStructureColumnRegex,
                this.modificationTypeColumnRegex,
            ];
            const additionalRegexList = this.selectedDatasetType === 'glycoproteins'
                ? [
                    this.fastaHeaderColumnRegex,
                    this.peptideStartingPositionColumnRegex,
                ]
                : [];
            return basicRegexList
                .concat(additionalRegexList)
                .every(regex => headerLine.some(header => regex.test(header)));
        }
        catch (_) {
            return false;
        }
    }
    async setStandardByonicConfig() {
        const targetSheet = this.worksheetNames.find(worksheetName => worksheetName === 'Spectra');
        if (!targetSheet) {
            return this.changeWorksheet(this.worksheetNames[0]);
        }
        await this.changeWorksheet(targetSheet);
        const headerLine = await this.getHeaderLine(targetSheet);
        this.startRow = 1;
        this.peptideSequenceColumn = headerLine.findIndex(h => this.peptideSequenceColumnRegex.test(h));
        this.glycanStructureColumn = headerLine.findIndex(h => this.glycanStructureColumnRegex.test(h));
        this.modificationTypeColumn = headerLine.findIndex(h => this.modificationTypeColumnRegex.test(h));
        if (this.selectedDatasetType === 'glycoproteins') {
            this.fastaHeaderColumn = headerLine.findIndex(h => this.fastaHeaderColumnRegex.test(h));
            this.peptideStartingPositionColumn = headerLine.findIndex(h => this.peptideStartingPositionColumnRegex.test(h));
        }
        this.setRowNumbers();
        return undefined;
    }
    shouldShowWorksheetBox() {
        return this.inputFileContent.type === 'excel';
    }
    shouldShowPeptideStartingPositionColumnBox() {
        return this.selectedDatasetType === 'glycoproteins';
    }
    async fileChangeHandler() {
        showLoadingOverlay();
        this.resetOptions();
        const fullPath = this.fileInputElement.value;
        if (fullPath === '') {
            // File is deselected
            this.inputFileName = 'empty';
            this.displayFileName = this.emptyFileName;
            // Unload the file content
            this.inputFileContent = {
                type: 'text',
                content: '',
            };
        }
        else {
            // File is selected
            const file = this.fileInputElement.files[0];
            this.inputFileName = file.name;
            this.displayFileName =
                file.name.length < this.maxFileNameLength
                    ? file.name
                    : `...${truncateLeading(file.name, this.maxFileNameLength)}`;
            // Load the file content
            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const buffer = await file.arrayBuffer();
                this.inputFileContent = {
                    type: 'excel',
                    content: buffer,
                };
            }
            else {
                const text = await file.text();
                this.inputFileContent = {
                    type: 'text',
                    content: text,
                };
            }
            this.isFileSelected = true;
        }
        await this.loadFileContent();
        hideLoadingOverlay();
    }
    async datasetTypeChangeHandler() {
        showLoadingOverlay();
        const selectedRadioItemOpt = Array.from(this.datasetTypeInputElementList).find(e => e.checked);
        if (!selectedRadioItemOpt) {
            this.isDatasetTypeSelected = false;
            await this.loadFileContent();
            this.isConversionReady = false;
            return;
        }
        const selectedDatasetType = selectedRadioItemOpt.value;
        if (selectedDatasetType === 'glycopeptides' ||
            selectedDatasetType === 'glycoproteins') {
            this.selectedDatasetType = selectedDatasetType;
            this.isDatasetTypeSelected = true;
        }
        else {
            this.selectedDatasetType = undefined;
            this.isDatasetTypeSelected = false;
        }
        await this.loadFileContent();
        const isStandardByonicResult = await this.isStandardByonicResultFile();
        if (isStandardByonicResult) {
            await this.setStandardByonicConfig();
        }
        this.checkConversionReady();
        hideLoadingOverlay();
    }
    async worksheetChangeHandler(e) {
        const elm = e.target;
        showLoadingOverlay();
        await this.changeWorksheet(elm.value);
        hideLoadingOverlay();
    }
    startRowChangeHandler(e) {
        const elm = e.target;
        const newVal = parseInt(elm.value, 10) - 1;
        if (newVal >= 0 && newVal <= this.endRow) {
            this.startRow = newVal;
        }
        else if (newVal < 0 || Number.isNaN(newVal)) {
            this.startRow = 0;
        }
        else {
            this.startRow = this.endRow;
        }
        this.setRowNumbers();
    }
    endRowChangeHandler(e) {
        const elm = e.target;
        const newVal = parseInt(elm.value, 10) - 1;
        if (this.startRow <= newVal && newVal < this.maxRow) {
            this.endRow = newVal;
        }
        else if (newVal < this.startRow) {
            this.endRow = this.startRow;
        }
        else {
            this.endRow = this.maxRow - 1;
        }
        this.setRowNumbers();
    }
    peptideSequenceColumnChangeHandler(e) {
        const elm = e.target;
        this.peptideSequenceColumn = parseInt(elm.value, 10);
        this.checkConversionReady();
    }
    glycanStructureColumnChangeHandler(e) {
        const elm = e.target;
        this.glycanStructureColumn = parseInt(elm.value, 10);
        this.checkConversionReady();
    }
    modificationTypeColumnChangeHandler(e) {
        const elm = e.target;
        this.modificationTypeColumn = parseInt(elm.value, 10);
        this.checkConversionReady();
    }
    fastaHeaderColumnChangeHandler(e) {
        const elm = e.target;
        this.fastaHeaderColumn = parseInt(elm.value, 10);
        this.checkConversionReady();
    }
    peptideStartingPositionColumnChangeHandler(e) {
        const elm = e.target;
        this.peptideStartingPositionColumn = parseInt(elm.value, 10);
        this.checkConversionReady();
    }
    async convert() {
        showLoadingOverlay();
        const result = await comberterUtilsWorker.convert(this.inputFileContent, this.worksheet, this.startRow, this.endRow, this.peptideSequenceColumn, this.glycanStructureColumn, this.modificationTypeColumn, this.fastaHeaderColumn, this.peptideStartingPositionColumn);
        this.emitEvent(result);
        hideLoadingOverlay();
    }
    render() {
        return html `
      <div class="columns">
        <div class="column is-12-tablet">
          <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical">
              <!--
              <nav class="tile is-child panel">
                <glycomb-collapsible-message
                  messageClass="is-dark"
                  messageHeaderClass="glycomb-registration-example-data-header"
                  headerTitle="How to use"
                >
                  <div class="glycomb-float-button-wrapper">
                    <pre
                      class="glycomb-registration-code-area"
                    ><code>aaa</code></pre>
                    <button class="button glycomb-float-button">
                      <span class="icon"
                        ><glycomb-fa-icon
                          icon="fas fa-copy"
                          size="is-6"
                        ></glycomb-fa-icon></span
                      ><span>Copy</span>
                    </button>
                  </div>
                </glycomb-collapsible-message>
              </nav>
              -->

              <nav class="tile is-child panel is-link">
                <p class="panel-heading">
                  1. Select a Byonic summary Excel file
                </p>
                <div class="panel-block">
                  <div class="container">
                    <div class="field">
                      <label class="label">
                        Select your worksheet to read (XLSX/XLS/TSV file
                        format):
                      </label>
                      <div class="control">
                        <div class="file has-name is-fullwidth">
                          <label class="file-label">
                            <input
                              class="file-input"
                              type="file"
                              accept=".xlsx,.xls,.tsv"
                              @change="${this.fileChangeHandler}"
                            />
                            <span class="file-cta">
                              <span class="file-icon">
                                <glycomb-fa-icon
                                  icon="fas fa-file-upload"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                              <span class="file-label">Select a file...</span>
                            </span>
                            <span class="file-name"
                              >${this.displayFileName}</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>

              <nav class="tile is-child panel is-link">
                <p class="panel-heading">
                  2. What kind of GlyComb datasets do you want to generate?
                </p>
                <div class="panel-block">
                  ${this.isFileSelected
            ? html `
                        <div class="container">
                          <fieldset>
                            <div class="field">
                              <label class="label">
                                Pick one of the following options:
                              </label>
                              <div class="control">
                                <label class="radio">
                                  <input
                                    type="radio"
                                    name="dataset_type"
                                    value="glycopeptides"
                                    @click="${this.datasetTypeChangeHandler}"
                                  />
                                  Glycopeptide datasets
                                </label>
                                <label class="radio">
                                  <input
                                    type="radio"
                                    name="dataset_type"
                                    value="glycoproteins"
                                    @click="${this.datasetTypeChangeHandler}"
                                  />
                                  Glycoprotein datasets
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      `
            : html ``}
                </div>
              </nav>

              <nav class="tile is-child panel is-link">
                <p class="panel-heading">
                  3. Specify the worksheet cell ranges to be read
                </p>
                <div class="panel-block">
                  ${this.isDatasetTypeSelected
            ? html `
                        <div class="container">
                          <div
                            class="field"
                            .hidden=${!this.shouldShowWorksheetBox()}
                          >
                            <label class="label"
                              >Select the worksheet to read the data</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select @change=${this.worksheetChangeHandler}>
                                  ${this.worksheetNames.map(worksheetName => html `<option
                                        value=${worksheetName}
                                        ?selected=${this.worksheet ===
                worksheetName}
                                      >
                                        ${worksheetName}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-file-excel"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>

                          <div class="field">
                            <label class="label"
                              >Select the rows containing the data (without
                              headers such as "Peptide &lt;ProteinMetrics
                              Confidential&gt;" or "Glycans NHFAGNa"
                              etc.).</label
                            >
                            <div class="columns">
                              <div class="column">
                                <label class="label">From</label>
                                <p class="control is-expanded has-icons-left">
                                  <input
                                    id="startRowInput"
                                    type="number"
                                    class="input"
                                    style="padding-left: 3.5em;"
                                    min="1"
                                    max="${this.endRow + 1}"
                                    step="1"
                                    required
                                    .value="${this.startRow + 1}"
                                    @change=${this.startRowChangeHandler}
                                  />
                                  <span
                                    class="icon is-small is-left"
                                    style="width: 3.5em;"
                                    >Row</span
                                  >
                                </p>
                              </div>
                              <div class="column">
                                <label class="label">To</label>
                                <p class="control is-expanded has-icons-left">
                                  <input
                                    id="endRowInput"
                                    type="number"
                                    class="input"
                                    style="padding-left: 3.5em;"
                                    min="${this.startRow + 1}"
                                    max="${this.maxRow}"
                                    step="1"
                                    required
                                    .value="${this.endRow + 1}"
                                    @change=${this.endRowChangeHandler}
                                  />
                                  <span
                                    class="icon is-small is-left"
                                    style="width: 3.5em;"
                                    >Row</span
                                  >
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <label class="label"
                              >Select the column containing the peptide
                              sequences such as
                              "R.GSISNLN[+1702.581]VSLC[+57.021]AR.Y".</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select
                                  @change=${this
                .peptideSequenceColumnChangeHandler}
                                >
                                  ${range(-1, this.maxColumn).map(idx => html `<option
                                        value=${idx}
                                        ?selected=${this
                .peptideSequenceColumn === idx}
                                      >
                                        ${idx === -1
                ? '---'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-map-marker"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>

                          <div class="field">
                            <label class="label"
                              >Select the column containing the glycan
                              structures such as "HexNAc(2)Hex(8)".</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select
                                  @change=${this
                .glycanStructureColumnChangeHandler}
                                >
                                  ${range(-1, this.maxColumn).map(idx => html `<option
                                        value=${idx}
                                        ?selected=${this
                .glycanStructureColumn === idx}
                                      >
                                        ${idx === -1
                ? '---'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-map-marker"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>

                          <div class="field">
                            <label class="label"
                              >Select the column containing the modification
                              type(s) such as "C[+57], N[+1703]".</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select
                                  @change=${this
                .modificationTypeColumnChangeHandler}
                                >
                                  ${range(-1, this.maxColumn).map(idx => html `<option
                                        value=${idx}
                                        ?selected=${this
                .modificationTypeColumn === idx}
                                      >
                                        ${idx === -1
                ? '---'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-map-marker"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>

                          <div class="field">
                            <label class="label"
                              >${this.selectedDatasetType === 'glycopeptides'
                ? '(Optional)'
                : ''}
                              Select the column containing the FASTA headers
                              including UniProt IDs such as
                              ">sp|P35918|VGFR2_MOUSE Vascular endothelial
                              growth factor receptor 2...".</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select
                                  @change=${this.fastaHeaderColumnChangeHandler}
                                >
                                  ${range(-1, this.maxColumn).map(idx => html `<option
                                        value=${idx}
                                        ?selected=${this.fastaHeaderColumn ===
                idx}
                                      >
                                        ${idx === -1
                ? '---'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-map-marker"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>

                          <div
                            class="field"
                            .hidden=${!this.shouldShowPeptideStartingPositionColumnBox()}
                          >
                            <label class="label"
                              >Select the column containing the peptide starting
                              position such as "125".</label
                            >
                            <p class="control is-expanded has-icons-left">
                              <span class="select is-fullwidth">
                                <select
                                  @change=${this
                .peptideStartingPositionColumnChangeHandler}
                                >
                                  ${range(-1, this.maxColumn).map(idx => html `<option
                                        value=${idx}
                                        ?selected=${this
                .peptideStartingPositionColumn ===
                idx}
                                      >
                                        ${idx === -1
                ? '---'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                                      </option>`)}
                                </select>
                              </span>
                              <span class="icon is-small is-left">
                                <glycomb-fa-icon
                                  icon="fas fa-map-marker"
                                  size="is-7"
                                ></glycomb-fa-icon>
                              </span>
                            </p>
                          </div>
                        </div>
                      `
            : html ``}
                </div>
              </nav>

              <nav class="tile is-child">
                <div class="buttons is-centered">
                  <button
                    class="button is-medium is-success"
                    .disabled=${!this.isConversionReady}
                    @click=${this.convert}
                  >
                    <span class="icon">
                      <glycomb-fa-icon
                        id="submitButtonIcon"
                        icon="fas fa-play-circle"
                        size="is-4"
                      ></glycomb-fa-icon>
                    </span>
                    <span>Start conversion</span>
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    `;
    }
};
GlyCombByonicConverterConfig.styles = style.cssResult;
__decorate([
    query('input.file-input')
], GlyCombByonicConverterConfig.prototype, "fileInputElement", void 0);
__decorate([
    queryAll('input[type="radio"][name="dataset_type"]')
], GlyCombByonicConverterConfig.prototype, "datasetTypeInputElementList", void 0);
__decorate([
    query('#startRowInput')
], GlyCombByonicConverterConfig.prototype, "startRowInputElement", void 0);
__decorate([
    query('#endRowInput')
], GlyCombByonicConverterConfig.prototype, "endRowInputElement", void 0);
__decorate([
    property({ type: String })
], GlyCombByonicConverterConfig.prototype, "displayFileName", void 0);
__decorate([
    property({ type: Object })
], GlyCombByonicConverterConfig.prototype, "inputFileContent", void 0);
__decorate([
    property({ type: String })
], GlyCombByonicConverterConfig.prototype, "selectedDatasetType", void 0);
__decorate([
    property({ type: Boolean })
], GlyCombByonicConverterConfig.prototype, "isFileSelected", void 0);
__decorate([
    property({ type: Boolean })
], GlyCombByonicConverterConfig.prototype, "isDatasetTypeSelected", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "maxRow", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "maxColumn", void 0);
__decorate([
    property({ type: Array })
], GlyCombByonicConverterConfig.prototype, "worksheetNames", void 0);
__decorate([
    property({ type: String })
], GlyCombByonicConverterConfig.prototype, "worksheet", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "startRow", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "endRow", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "peptideSequenceColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "glycanStructureColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "modificationTypeColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "fastaHeaderColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombByonicConverterConfig.prototype, "peptideStartingPositionColumn", void 0);
__decorate([
    property({ type: Boolean })
], GlyCombByonicConverterConfig.prototype, "isConversionReady", void 0);
GlyCombByonicConverterConfig = __decorate([
    customElement('glycomb-byonic-converter-config')
], GlyCombByonicConverterConfig);
export { GlyCombByonicConverterConfig };
