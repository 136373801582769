var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import * as comlink from 'comlink';
import { range } from '@glycomb/glycomb-utils';
import { showLoadingOverlay, hideLoadingOverlay, } from '@/js/widgets/glycomb-loading-overlay';
import { getColumnDisplayName } from '@/js/utilities/view_helper';
const style = await import('@/js/style');
const uploadFileParserWorker = comlink.wrap(new Worker(new URL('/js/workers/glycopeptide_upload_file_parser.worker.ts', import.meta.url)));
let GlyCombGlycoPeptideFileUploadConfig = class GlyCombGlycoPeptideFileUploadConfig extends LitElement {
    constructor() {
        super(...arguments);
        this.inputFileFormat = 'byonic';
        this.maxRow = -1;
        this.maxColumn = -1;
        this.worksheetNames = [];
        this.worksheet = '';
        this.startRow = 0;
        this.aaSeqColumn = -1;
        this.siteColumn = -2;
        this.glycanColumn = -1;
        this.modificationColumn = -1;
        this.additionalDataColumn = -1;
    }
    get inputFileContent() {
        return this._inputFileContent;
    }
    set inputFileContent(newVal) {
        if (this._inputFileContent !== newVal) {
            this._inputFileContent = newVal;
            this.inputFileFormat = 'byonic';
            this.maxRow = -1;
            this.maxColumn = -1;
            this.worksheetNames = [];
            this.worksheet = '';
            this.startRow = 0;
            this.aaSeqColumn = -1;
            this.siteColumn = -2;
            this.glycanColumn = -1;
            this.modificationColumn = -1;
            this.additionalDataColumn = -1;
            if (newVal.content !== '') {
                showLoadingOverlay();
                if (this._inputFileContent.type === 'text') {
                    Promise.all([
                        uploadFileParserWorker.getMaxRow(this._inputFileContent, ''),
                        uploadFileParserWorker.getMaxColumn(this._inputFileContent, ''),
                    ]).then(([maxRow, maxColumn]) => {
                        this.maxRow = maxRow;
                        this.maxColumn = maxColumn;
                        hideLoadingOverlay();
                    });
                }
                else if (this._inputFileContent.type === 'excel') {
                    uploadFileParserWorker
                        .getWorksheetNames(this._inputFileContent.content)
                        .then((worksheetNames) => {
                        this.worksheetNames = worksheetNames;
                        return this.changeWorksheet(worksheetNames[0]);
                    })
                        .then((_) => {
                        hideLoadingOverlay();
                    });
                }
            }
            this.setRowNumbers();
        }
    }
    checkSubmitReady() {
        return ((this.inputFileFormat === 'byonic' &&
            this.aaSeqColumn >= 0 &&
            this.siteColumn >= -1 &&
            this.glycanColumn >= 0) ||
            (this.inputFileFormat === 'msviewer' &&
                this.aaSeqColumn >= 0 &&
                this.modificationColumn >= 0));
    }
    setRowNumbers() {
        if (this.startRowInputElement) {
            this.startRowInputElement.value = (this.startRow + 1).toString();
        }
    }
    emitEvent() {
        this.dispatchEvent(new CustomEvent('glycomb-glycopeptide-file-upload-config-changed', {
            bubbles: true,
            composed: true,
        }));
    }
    fileFormatChangeHandler(e) {
        const elm = e.target;
        this.inputFileFormat = elm.value;
        this.emitEvent();
    }
    worksheetChangeHandler(e) {
        const elm = e.target;
        showLoadingOverlay();
        this.changeWorksheet(elm.value).then(_ => {
            hideLoadingOverlay();
            this.emitEvent();
        });
    }
    startRowChangeHandler(e) {
        const elm = e.target;
        const newVal = parseInt(elm.value, 10) - 1;
        if (newVal >= 0 && newVal < this.maxRow) {
            this.startRow = newVal;
        }
        else if (newVal < 0 || Number.isNaN(newVal)) {
            this.startRow = 0;
        }
        else {
            this.startRow = this.maxRow - 1;
        }
        this.setRowNumbers();
        this.emitEvent();
    }
    aaSeqColumnChangeHandler(e) {
        const elm = e.target;
        this.aaSeqColumn = parseInt(elm.value, 10);
        this.emitEvent();
    }
    siteColumnChangeHandler(e) {
        const elm = e.target;
        this.siteColumn = parseInt(elm.value, 10);
        this.emitEvent();
    }
    glycanColumnChangeHandler(e) {
        const elm = e.target;
        this.glycanColumn = parseInt(elm.value, 10);
        this.emitEvent();
    }
    modificationColumnChangeHandler(e) {
        const elm = e.target;
        this.modificationColumn = parseInt(elm.value, 10);
        this.emitEvent();
    }
    additionalDataColumnChangeHandler(e) {
        const elm = e.target;
        this.additionalDataColumn = parseInt(elm.value, 10);
        this.emitEvent();
    }
    async changeWorksheet(worksheetName) {
        this.worksheet = worksheetName;
        this.startRow = 0;
        this.aaSeqColumn = -1;
        this.siteColumn = -2;
        this.glycanColumn = -1;
        this.modificationColumn = -1;
        this.additionalDataColumn = -1;
        this.setRowNumbers();
        return Promise.all([
            uploadFileParserWorker.getMaxRow(this._inputFileContent, this.worksheet),
            uploadFileParserWorker.getMaxColumn(this._inputFileContent, this.worksheet),
        ]).then(([maxRow, maxColumn]) => {
            this.maxRow = maxRow;
            this.maxColumn = maxColumn;
        });
    }
    async generateContent() {
        const result = await uploadFileParserWorker.generateContent(this.inputFileContent, this.inputFileFormat, this.worksheet, this.startRow, this.aaSeqColumn, this.siteColumn, this.glycanColumn, this.modificationColumn, this.additionalDataColumn);
        return result;
    }
    shouldShowWorksheetBox() {
        return this.inputFileContent.type === 'excel';
    }
    shouldShowStartRowBox() {
        return this.maxRow > 0;
    }
    shouldShowAASeqColumnBox() {
        return this.maxColumn > 0;
    }
    shouldShowSiteColumnBox() {
        return this.maxColumn > 0 && this.inputFileFormat === 'byonic';
    }
    shouldShowGlycanColumnBox() {
        return this.maxColumn > 0 && this.inputFileFormat !== 'msviewer';
    }
    shouldShowModificationColumnBox() {
        return this.maxColumn > 0 && this.inputFileFormat === 'msviewer';
    }
    shouldShowAdditionalDataColumnBox() {
        return this.maxColumn > 0;
    }
    render() {
        return html `
      <div>
        <div class="field" .hidden=${!this.shouldShowWorksheetBox()}>
          <label class="label">Choose the worksheet to read the data</label>
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.worksheetChangeHandler}>
                ${this.worksheetNames.map(worksheetName => html `<option
                      value=${worksheetName}
                      ?selected=${this.worksheet === worksheetName}
                    >
                      ${worksheetName}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-file-excel"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowStartRowBox()}>
          <label class="label"
            >Choose the start row number to read the data (without
            headers)</label
          >
          <p class="control is-expanded has-icons-left">
            <input
              id="startRowInput"
              type="number"
              class="input"
              style="padding-left: 3.5em;"
              min="1"
              max="${this.maxRow}"
              step="1"
              required
              .value="${this.startRow + 1}"
              @change=${this.startRowChangeHandler}
            />
            <span class="icon is-small is-left" style="width: 3.5em;">Row</span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowAASeqColumnBox()}>
          <label class="label"
            >Choose the column for amino acid sequences</label
          >
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.aaSeqColumnChangeHandler}>
                ${range(-1, this.maxColumn).map(idx => html `<option
                      value=${idx}
                      ?selected=${this.aaSeqColumn === idx}
                    >
                      ${idx === -1
            ? '---'
            : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-map-marker"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowSiteColumnBox()}>
          <label class="label">Choose the column for glycosylation sites</label>
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.siteColumnChangeHandler}>
                ${range(-2, this.maxColumn).map(idx => html `<option
                      value=${idx}
                      ?selected=${this.siteColumn === idx}
                    >
                      ${idx === -2
            ? '---'
            : idx === -1
                ? 'Use the first lower-case letter position for glycosylation site in the amino acid sequence column'
                : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-map-marker"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowGlycanColumnBox()}>
          <label class="label">Choose the column for glycan structures</label>
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.glycanColumnChangeHandler}>
                ${range(-1, this.maxColumn).map(idx => html `<option
                      value=${idx}
                      ?selected=${this.glycanColumn === idx}
                    >
                      ${idx === -1
            ? '---'
            : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-map-marker"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowModificationColumnBox()}>
          <label class="label">Choose the column for modifications</label>
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.modificationColumnChangeHandler}>
                ${range(-1, this.maxColumn).map(idx => html `<option
                      value=${idx}
                      ?selected=${this.modificationColumn === idx}
                    >
                      ${idx === -1
            ? '---'
            : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-map-marker"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>

        <div class="field" .hidden=${!this.shouldShowAdditionalDataColumnBox()}>
          <label class="label">Choose the column for additional data</label>
          <p class="control is-expanded has-icons-left">
            <span class="select is-fullwidth">
              <select @change=${this.additionalDataColumnChangeHandler}>
                ${range(-1, this.maxColumn).map(idx => html `<option
                      value=${idx}
                      ?selected=${this.additionalDataColumn === idx}
                    >
                      ${idx === -1
            ? '---'
            : `Column ${getColumnDisplayName(idx, this.inputFileContent)}`}
                    </option>`)}
              </select>
            </span>
            <span class="icon is-small is-left">
              <glycomb-fa-icon
                icon="fas fa-map-marker"
                size="is-7"
              ></glycomb-fa-icon>
            </span>
          </p>
        </div>
      </div>
    `;
    }
};
GlyCombGlycoPeptideFileUploadConfig.styles = style.cssResult;
__decorate([
    query('#startRowInput')
], GlyCombGlycoPeptideFileUploadConfig.prototype, "startRowInputElement", void 0);
__decorate([
    property({ type: Object })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "inputFileContent", null);
__decorate([
    property({ type: String })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "inputFileFormat", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "maxRow", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "maxColumn", void 0);
__decorate([
    property({ type: Array })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "worksheetNames", void 0);
__decorate([
    property({ type: String })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "worksheet", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "startRow", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "aaSeqColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "siteColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "glycanColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "modificationColumn", void 0);
__decorate([
    property({ type: Number })
], GlyCombGlycoPeptideFileUploadConfig.prototype, "additionalDataColumn", void 0);
GlyCombGlycoPeptideFileUploadConfig = __decorate([
    customElement('glycomb-glycopeptide-file-upload-config')
], GlyCombGlycoPeptideFileUploadConfig);
export { GlyCombGlycoPeptideFileUploadConfig };
