export class GlyCombBrowserLocalError extends Error {
    constructor(msg, glycombErrcode) {
        super(msg);
        this.glycombErrcode = glycombErrcode;
    }
}
export class GlyCombApiCallError extends Error {
    constructor(msg, httpStatusCodeOpt, glycombErrcode) {
        super(msg);
        this.httpStatusCodeOpt = httpStatusCodeOpt;
        this.glycombErrcode = glycombErrcode;
    }
}
