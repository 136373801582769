import izitoast from 'izitoast';
import * as Option from 'fp-ts/Option';
import * as Either from 'fp-ts/Either';
import { handleErrorPromise } from '@glycomb/glycomb-utils/error_handling';
import { StatusCodeError, safeFetch as _safeFetch, } from '@glycomb/glycomb-utils/safe_fetch';
import { GlyCombBrowserLocalError, GlyCombApiCallError, } from '@/js/types/error_handling';
import config from '@/js/config';
import messages from '@/js/utilities/error_messages';
function showErrorMessage(message) {
    // Show error message.
    izitoast.error({
        title: 'Error',
        position: 'topRight',
        timeout: config.toast_notification_timeout,
        message,
    });
    console.error(message);
}
function getGlyCombErrorMessageFromErrcode(errcode) {
    const targetMessage = messages.find(m => m.code === errcode);
    if (targetMessage) {
        return `${errcode}: ${targetMessage.message}`;
    }
    return (`Unknown errcode "${errcode}" was returned. ` +
        'Please report this error to your system administrator! ' +
        `If you are the system administrator, please update <a href=${config.yaml_location}>this document</a> and add this errcode to it.`);
}
/**
 * handleGlyCombError: (() => Promise<T>) => Promise<Option.Option<T>>
 * 非同期関数 f を実行し、実行中に GlyComb 関連のエラー
 * (GlyCombAPICallError, GlyCombBrowserLocalError) が発生しなかった
 * 場合には Some<T> を含み、エラーが発生した場合には None<T> を含む
 * Promise を返す非同期関数. エラー発生時には副作用としてエラー表示処理が
 * 実行される.
 */
export async function handleGlyCombError(f) {
    const errorHandler = async (e) => {
        const generateMessage = () => {
            if (e instanceof GlyCombApiCallError) {
                if (Option.isSome(e.httpStatusCodeOpt)) {
                    const httpStatusCode = e.httpStatusCodeOpt.value;
                    if (httpStatusCode === 200) {
                        // Handling GlyComb API error
                        return getGlyCombErrorMessageFromErrcode(e.glycombErrcode);
                    }
                    // 404 error, 500 error etc.
                    return `Failed to fetch from GlyComb API. (HTTP status code: ${httpStatusCode})`;
                }
                // Network error, CORS error etc.
                return `Failed to access to GlyComb API. (${e.message})`;
            }
            if (e instanceof GlyCombBrowserLocalError) {
                return getGlyCombErrorMessageFromErrcode(e.glycombErrcode);
            }
            // Rethrow the error
            throw e;
        };
        showErrorMessage(generateMessage());
    };
    const resultEither = await handleErrorPromise(errorHandler)(f);
    return Option.fromEither(resultEither);
}
/**
 * safeFetch での GlyComb API 実行の際にエラーハンドラで指定する
 * エラーハンドリング用の関数. エラーオブジェクトから
 * GlyCombAPICallError オブジェクトを生成し呼び出し元に返却する.
 */
export function generateGlyCombAPICallError(e) {
    if (e instanceof StatusCodeError) {
        // HTTP status code 404, 500 etc.
        return new GlyCombApiCallError(e.statusText, Option.of(e.status), '');
    }
    // Cannot reach GlyComb API (Network error, CORS error etc.)
    return new GlyCombApiCallError(e.message, Option.none, '');
}
export const safeFetch = _safeFetch(generateGlyCombAPICallError);
export function handleError(f) {
    const handler = (e) => e;
    return Either.tryCatch(f, handler);
}
