import * as A from 'fp-ts/Array';
import { transpose } from 'fp-ts-std/Array';
import { Parser } from 'tsv';
import * as xlsx from 'xlsx';
const tsvParser = new Parser('\t', { header: false });
const optForXlsx = {
    FS: '\t',
    RS: '\n',
    strip: true,
    blankrows: true,
    skipHidden: false,
};
// <https://github.com/SheetJS/sheetjs/issues/2360>
const replaceNewLines = (sheet) => {
    const range = xlsx.utils.decode_range(sheet['!ref']);
    for (let row = range.s.r; row <= range.e.r; row += 1) {
        for (let col = range.s.c; col <= range.e.c; col += 1) {
            const addr = xlsx.utils.encode_cell({
                r: row,
                c: col,
            });
            if (sheet[addr] && sheet[addr].t === 's') {
                const rawStr = sheet[addr].v.replace(/\r?\n/g, ' ');
                // Remove `h`, `r`, and `w` keys.
                // <https://docs.sheetjs.com/docs/csf/cell/>
                sheet[addr] = { t: 's', v: rawStr };
            }
        }
    }
    return sheet;
};
export const extractTsv = (content, sheetName) => {
    if (content.type === 'excel') {
        const body = content.content;
        const sheet = xlsx.read(body).Sheets[sheetName];
        return xlsx.utils.sheet_to_csv(replaceNewLines(sheet), optForXlsx);
    }
    // content.type === 'text'
    return content.content;
};
export const parseTsv = (tsv) => {
    const data = tsvParser.parse(tsv.replace(/\r?\n/g, '\n').trim());
    return data.map(line => (line.length === 1 && line[0] === '' ? [] : line));
};
export const printTsv = (data) => data.map(row => row.join('\t')).join('\n');
export const convertColumnsToRows = (requiredColumnList, optionalColumnList) => {
    // Convert to empty lists if all of the row contents is empty string.
    const requiredRowList = transpose(requiredColumnList).map(row => row.every(s => s === '') ? [] : row);
    const optionalRowList = transpose(optionalColumnList).map(row => row.every(s => s === '') ? [] : row);
    const resultRowList = optionalColumnList.length === 0
        ? requiredRowList
        : A.zip(requiredRowList, optionalRowList).map(([requiredRow, optionalRow]) => requiredRow.length === 0
            ? requiredRow
            : [...requiredRow, ...optionalRow]);
    return resultRowList;
};
export const getWorksheetNames = (buffer) => {
    const workbook = xlsx.read(buffer);
    return workbook.SheetNames;
};
export const getMaxRow = (content, sheetName) => {
    const tsv = extractTsv(content, sheetName);
    return parseTsv(tsv).length;
};
export const getMaxColumn = (content, sheetName) => {
    const tsv = extractTsv(content, sheetName);
    return parseTsv(tsv)
        .filter(line => line.length !== 0)
        .reduce((max, line) => (max > line.length ? max : line.length), 0);
};
const isEmpty = (array) => array.length === 0;
const getLastItem = (array) => array[array.length - 1];
export const insertBlankLinesToTsvString = (tsvString) => {
    const input = parseTsv(tsvString.trim());
    if (isEmpty(input)) {
        return printTsv(input);
    }
    const inputWithBlankLines = input.reduce((acc, row) => {
        if (isEmpty(acc.lines)) {
            // This is the first line of a dataset.
            return {
                lines: [row],
                prevSequence: row[0],
            };
        }
        if (isEmpty(getLastItem(acc.lines)) && isEmpty(row)) {
            // The two or more consecutive blank lines will be ignored.
            return acc;
        }
        if (isEmpty(row)) {
            // A manually inputted blank line detected.
            return {
                lines: [...acc.lines, row],
                prevSequence: '',
            };
        }
        // Compare the latest two lines with their sequences.
        // If the sequences differ, insert a blank line between them.
        const currSequence = row[0];
        if (currSequence === acc.prevSequence) {
            // The dataset continues...
            return {
                lines: [...acc.lines, row],
                prevSequence: acc.prevSequence,
            };
        }
        if (acc.prevSequence === '') {
            // The latest line was a separator line.
            // So, this row is the first line of the new dataset.
            return {
                lines: [...acc.lines, row],
                prevSequence: currSequence,
            };
        }
        // The sequence changed here. So, this is the first line of the new dataset.
        return {
            lines: [...acc.lines, [], row],
            prevSequence: currSequence,
        };
    }, {
        lines: [],
        prevSequence: '',
    });
    return printTsv(inputWithBlankLines.lines);
};
